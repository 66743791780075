import React, { useEffect, useState } from "react";
import StocksTable from "../components/stocks/StocksTable";
import BottomPageTitle from "../components/header/BottomPageTitle";
import BackButton from "../components/global/BackButton";
import { getAllRecords } from "../db/dexie-db/functions";
import { message } from "antd";

export default function Stockspage() {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const stocksFromDb = await getAllRecords("Stocks");
        const storageAndProductIds = stocksFromDb.reduce(
          (acc, stock) => {
            return {
              storageIds: [...acc.storageIds, stock.storageGuid],
              productIds: [...acc.productIds, stock.productGuid],
            };
          },
          { storageIds: [], productIds: [] }
        );
        console.log(storageAndProductIds);

        const uniqueStorageIds = [...new Set(storageAndProductIds.storageIds)];
        const uniqueProductIds = [...new Set(storageAndProductIds.productIds)];

        try {
          const allStorages = await getAllRecords("Storages");
          const storages = allStorages.filter((storage) =>
            uniqueStorageIds.includes(storage.guid)
          );

          const allProducts = await getAllRecords("Products");
          const products = allProducts.filter((product) =>
            uniqueProductIds.includes(product.guid)
          );

          const stocks = stocksFromDb.map((stock) => {
            const product = products.find(
              (product) => product.guid === stock.productGuid
            );
            const storage = storages.find(
              (storage) => storage.guid === stock.storageGuid
            );

            return { product, storage, productStock: stock.productStock };
          });

          console.log(stocks);
          setStocks(stocks);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        message.error("[ERROR] - failed to load stocks");
      }
    };

    getData();
  }, []);

  return (
    <>
      <StocksTable loading={loading} stocks={stocks}></StocksTable>
      <BottomPageTitle title="Залишки"></BottomPageTitle>
      <BackButton></BackButton>
    </>
  );
}
