import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Space,
  Switch,
  Radio,
  message,
} from "antd";
import React, { useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import ModalActions from "./ModalActions";
import dayjs from "dayjs";
import ModalCreateIco from "./ModalCreateIco";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const tablePageSizeValue = +localStorage.getItem("debtsPageSize")
  ? +localStorage.getItem("debtsPageSize")
  : 15;

export default function DebtsTable(props) {
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [tablePageSize, setTablePageSize] = useState(tablePageSizeValue);
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [isModalCreateIcoOpen, setIsModalCreateIcoOpen] = useState(false);

  const [debtData, setDebtData] = useState({});

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const onChildrenRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setDebtData({ document: record });
        setIsModalActionsOpen(true);
      },
    };
  };

  const columns = [
    {
      title: "Клієнт",
      dataIndex: "clientName",
      sorter: (a, b) => {
        return a.clientName.localeCompare(b.clientName);
      },
      key: "clientName",
      width: "75%",
      ...getColumnSearchProps("clientName"),
    },
    {
      title: "Борг",
      dataIndex: "debtValue",
      sorter: (a, b) => {
        return a.debtValue - b.debtValue;
      },
      key: "debtValue",
      width: "25%",
    },
  ];

  const expandedRowRender = (row) => {
    const childColumns = [
      {
        title: "Дата",
        dataIndex: "docDate",
        key: "docDate",
        render: (_, record) => {
          const created = dayjs(record.docDate, "DD.MM.YYYY HH:mm:ss").format(
            "DD.MM HH:mm"
          );
          return created;
        },
      },
      {
        title: "Документ",
        dataIndex: "docName",
        key: "docName",
      },
      {
        title: "Сума",
        dataIndex: "docDebt",
        key: "docDebt",
      },
    ];

    const data = [...row.documents];
    return (
      <Table
        columns={childColumns}
        onRow={onChildrenRow}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const onChangePageSize = ({ target: { value } }) => {
    if (value === 9999) {
      message.info(
        "При встановленні цього параметра таблиця працюватиме повільніше!"
      );
    }
    localStorage.setItem("debtsPageSize", value);
    setTablePageSize(value);
  };

  return (
    <>
      <Row style={{ justifyContent: "center" }}>
        <Col span={24}>
          <Table
            loading={props.loading}
            pagination={{
              disabled: false,
              position: ["bottomCenter"],
              hideOnSinglePage: false,
              showSizeChanger: false,
              size: "default",
              pageSize: tablePageSize,
            }}
            // onRow={onRowAction}
            columns={columns}
            dataSource={props.debts}
            // onChange={onChange}
            expandable={{
              expandedRowRender,
              expandRowByClick: true,
              // defaultExpandedRowKeys: defaulExpandedRow,
            }}
            size="small"
            tableLayout="fixed"
          />
          <ModalActions
            isModalActionsOpen={isModalActionsOpen}
            setIsModalActionsOpen={setIsModalActionsOpen}
            debtData={debtData}
            setIsModalCreateIcoOpen={setIsModalCreateIcoOpen}
          ></ModalActions>
          <ModalCreateIco
            isModalCreateIcoOpen={isModalCreateIcoOpen}
            setIsModalCreateIcoOpen={setIsModalCreateIcoOpen}
            documentSource={debtData.document}
          ></ModalCreateIco>
        </Col>
        <Space direction="horizontal">
          По
          <Radio.Group
            options={[
              {
                label: 15,
                value: 15,
              },
              {
                label: 100,
                value: 100,
              },
              {
                label: "∞",
                value: 9999,
              },
            ]}
            onChange={onChangePageSize}
            value={+tablePageSize}
            optionType="button"
            buttonStyle="solid"
          />
          записів
        </Space>
      </Row>
    </>
  );
}
