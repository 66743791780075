import { getRecordByMyltipleKey, updateRecordInIndexedDb } from "./functions";
import dayjs from "dayjs";
import { message } from "antd";
import {
  getRecordByKeyValue,
  getAllRecords,
  getRecords,
  deleteRecord,
  getRecordsByKeywords,
} from "../../db/dexie-db/functions";
import { TABLES } from "../../_CONSTANTS/constants";
import { getUniqueArrayOfObjects } from "../../utils/arrayFunctions";

export async function getTodayDocuments(table) {
  const todayDocuments = await getRecords(
    table,
    "date",
    dayjs().format("DD.MM.YY")
  );
  return todayDocuments;
}

export async function getDocForExport(doc) {
  const docForExport = {};
  const docProducts = doc.products.map((product) => {
    return {
      guid: product.guid,
      qty: product.qty,
      price: product.price,
    };
  });
  docForExport.comment = doc.comment;
  docForExport.id = doc.id;
  docForExport.client = doc.clientGuid;
  docForExport.clientName = doc.clientName;
  docForExport.priceType = doc.priceTypeGuid;
  docForExport.storage = doc.storageGuid;
  docForExport.sum = doc.sum;
  docForExport.firm = doc.firmGuid;
  docForExport.coordinates = doc.coordinates;
  docForExport.dateTime = dayjs(doc.modified.$d).format("DD.MM.YYYY HH:mm:ss");
  docForExport.products = docProducts;
  docForExport.documentId = doc.documentId;
  docForExport.propertyName = doc?.docPropertyName;
  docForExport.propertyGuid = doc?.docPropertyGuid;
  return docForExport;
}

export async function getDocIcoForExport(doc) {
  const docForExport = {};
  docForExport.comment = doc.comment;
  docForExport.id = doc.id;
  docForExport.documentId = doc.documentId;
  docForExport.client = doc.clientGuid;
  docForExport.clientName = doc.clientName;
  docForExport.sourceDocGuid = doc.sourceDocGuid;
  docForExport.sum = doc.sum;
  docForExport.dateTime = dayjs(doc.modified.$d).format("DD.MM.YYYY HH:mm:ss");
  return docForExport;
}

export async function getDocumentsForExport(documents, type) {
  return await Promise.all(
    documents.map(async (doc) => {
      if (type === "ico") {
        return await getDocIcoForExport(doc);
      }
      if (type === "order") {
        return await getDocForExport(doc);
      }
    })
  );
}

export async function changeDocumentsExportStatusInIndexedDb({
  documents,
  updateDocument,
}) {
  return await Promise.all(
    documents.map(async (doc) => {
      try {
        await updateRecordInIndexedDb(doc, updateDocument);
      } catch (error) {
        message.error("Помилка оновлення статусу");
        console.log(error);
        throw error;
      }
    })
  );
}

export function filterNotExportedDocuments(exportedDocuments, documents) {
  return documents.filter((document) => {
    const existingExportedDoc = exportedDocuments.find(
      (exportedDocument) => exportedDocument.id === document.id
    );

    return !Boolean(existingExportedDoc);
  });
}

export async function setDisplayDataToDocuments(documents) {
  return await Promise.all(
    documents.map(async (order, index) => {
      const client = await getRecordByKeyValue(TABLES.CLIENTS, {
        guid: order.clientGuid,
      });
      order.clientName = client?.name;
      return order;
    })
  );
}

export async function getFormattedDocuments(documents) {
  return await Promise.all(
    documents.map(async (order, index) => {
      const client = await getRecordByKeyValue(TABLES.CLIENTS, {
        guid: order.clientGuid,
      });

      order.clientName = client?.name;
      order.key = order.id;
      order.index = index + 1;
      return order;
    })
  );
}

export function setKeysAndIndexToArray(array) {
  return array.map((element, index) => {
    element.key = index + 1;
    element.index = index + 1;
    return element;
  });
}

export async function getClientsData() {
  const clientsFromDb = await getAllRecords(TABLES.CLIENTS);
  const clients = clientsFromDb.map((client) => {
    return (client = {
      label: client.name,
      value: client.guid,
    });
  });
  return clients;
}

export async function getStoragesData() {
  const storagesFromDb = await getAllRecords(TABLES.STORAGES);
  const storages = storagesFromDb.map((storage) => {
    return (storage = {
      label: storage.name,
      value: storage.guid,
    });
  });
  return storages;
}

export const getDocProperties = async () => {
  const docProperties = await getAllRecords(TABLES.DOCUMENT_PROPERTIES);
  return docProperties.map((property) => {
    return (property = {
      label: property.name,
      value: property.guid,
    });
  });
};

export const getFirmsData = async () => {
  const firmsFromDb = await getAllRecords(TABLES.FIRMS);
  const firms = firmsFromDb.map((firm) => {
    return (firm = {
      label: firm.name,
      value: firm.guid,
    });
  });
  return firms;
};

export const getPriceTypesData = async () => {
  const priceTypesFromDb = await getAllRecords(TABLES.PRICE_TYPES);
  const priceTypes = priceTypesFromDb.map((priceType) => {
    return (priceType = {
      label: priceType.name,
      value: priceType.guid,
    });
  });
  return priceTypes;
};

export function getRecordsForTable(records) {
  return records.map((doc, index) => {
    doc.key = index + 1;
    doc.index = index + 1;
    return doc;
  });
}

export const getDocumentId = (userId) => {
  const time = dayjs().valueOf().toString();
  const barcode = time.substring(time.length - 9);
  const id = userId;
  const fullId = ("0000" + id).slice(-4);

  return fullId + barcode;
};

export async function searchProductInDbByTitle(data) {
  try {
    const records = await getRecordsByKeywords(TABLES.PRODUCTS, "title", data);
    if (records.length) return records;
    return false;
    // const recordByArt = await getRecordByKeyValue("Products", { art: data });
    // if (!recordByArt) return false;
    // return [recordByArt];
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function searchProductInDb(data) {
  try {
    const productByBarcode = await getRecordByMyltipleKey(
      TABLES.PRODUCTS,
      data
    );

    if (productByBarcode) {
      return productByBarcode;
    }

    return false;
  } catch (error) {
    console.log(error);
    alert("Помилка пошуку товару" + error);
    return false;
  }
}

export const getProductsFromPrevOrders = async (clientGuid) => {
  const prevDocuments = await getRecords(
    TABLES.ORDERS,
    "clientGuid",
    clientGuid
  );

  const productsFromPrevOrders = prevDocuments.reduce(
    (products, currentDoc) => {
      return [...products, ...currentDoc.products];
    },
    []
  );

  return getUniqueArrayOfObjects(productsFromPrevOrders, "guid");
};

export const deleteOldServicesDocuments = async (daysForStoring) => {
  const orders = await getAllRecords(TABLES.ORDERS);
  const icos = await getAllRecords(TABLES.ICO);

  const ordersToDelete =
    orders && checkToDeleteDocuments(orders, daysForStoring);
  const icosToDelete = icos && checkToDeleteDocuments(icos, daysForStoring);

  if (ordersToDelete.length)
    (await deleteOldDocuments(ordersToDelete, TABLES.ORDERS))
      ? message.success(
          `Замовлення старші ${daysForStoring} днів успішно видалені`,
          3
        )
      : message.error("Помилка видалення старих замовлень", 3);

  if (icosToDelete.length)
    (await deleteOldDocuments(icosToDelete, TABLES.ICO))
      ? message.success(`ПКО старші ${daysForStoring} днів успішно видалені`, 3)
      : message.error("Помилка видалення старих ПКО", 3);
};

export const checkToDeleteDocuments = (documents, daysForStoring) => {
  const documentsToDelete = documents.map((document) => {
    const docDate = dayjs(document.date, "DD.MM.YY");
    const diff = dayjs().diff(docDate, "d");

    if (diff > daysForStoring) {
      return document;
    }
  });

  return documentsToDelete.filter((item) => item);
};

export const deleteOldDocuments = async (documents, serviceTable) => {
  const result = await Promise.all(
    documents.map(async (document) => {
      return await deleteRecord(serviceTable, document.id);
    })
  );
  return result;
};
