import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { isUpdateNeeded } from "../api/isUpdateNeeded";
import UpdateAvailableModal from "../components/import/UpdateAvailableModal";
import { useAuth } from "../hook/useAuth";
import ImportModal from "../components/import/ImportModal";
import { useTheme } from "../hook/useTheme";
import { ConfigProvider, theme } from "antd";
const { darkAlgorithm } = theme;

const Layout = () => {
  const { useDarkMode, setUseDarkMode } = useTheme();
  const { user } = useAuth();
  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);
  const [isOpenUpdateAvailableModal, setIsOpenUpdateAvailableModal] =
    useState(false);

  const userUseDarkMode =
    localStorage.getItem("useDarkMode") === "true" ? true : false;
  useEffect(() => {
    setUseDarkMode(userUseDarkMode);
    setTheme(userUseDarkMode);
  }, []);

  useEffect(() => {
    setTheme(useDarkMode);
  }, [useDarkMode]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const updated = localStorage.getItem("updated");
    const updatedNeeded = await isUpdateNeeded(updated, user.username);

    if (!updated || updatedNeeded) {
      return updateData();
    }
  };

  const updateData = async () => {
    setIsOpenUpdateAvailableModal(true);
  };

  const darkMode = {
    algorithm: darkAlgorithm,
    token: {
      colorPrimary: "#6b6b6b",
    },
  };

  const lightMode = {
    token: {
      colorPrimary: "#202124",
    },
  };

  const setTheme = (useDarkMode) => {
    if (useDarkMode) {
      const root = document.getElementById("root");
      root.style.backgroundColor = "black";
      document.documentElement.style.backgroundColor = "black";
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      const root = document.getElementById("root");
      root.style.backgroundColor = "white";
      document.documentElement.style.backgroundColor = "white";
      document.documentElement.setAttribute("data-bs-theme", "light");
    }
  };

  return (
    <>
      <ConfigProvider theme={useDarkMode ? darkMode : lightMode}>
        <div style={{ padding: "0px 5px" }}>
          <Outlet></Outlet>
          <UpdateAvailableModal
            open={isOpenUpdateAvailableModal}
            setIsOpenUpdateAvailableModal={setIsOpenUpdateAvailableModal}
            setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
          ></UpdateAvailableModal>
          <ImportModal
            isOpenUpdateDataModal={isOpenUpdateDataModal}
            setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
          ></ImportModal>
        </div>
        <div style={{ paddingBottom: "100px" }}></div>
      </ConfigProvider>
    </>
  );
};

export default Layout;
