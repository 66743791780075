import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackTo from "../../components/global/BackTo";
import { message, Row, Col } from "antd";
import IcoForm from "../../components/documents/incoming-cash-orders/Ico-form";
import { getAllRecords } from "../../db/dexie-db/functions";

const CreateIco = () => {
  const [clients, setClients] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const getFieldsData = async () => {
      try {
        const clientsFromDb = await getAllRecords("Clients");
        const clients = clientsFromDb.map((client) => {
          return (client = {
            label: client.name,
            value: client.guid,
          });
        });
        setClients(clients);
      } catch (error) {
        message.error("Помилка отримання клієнтів");
      }
    };
    getFieldsData();
  }, []);

  return (
    <>
      <Row style={{ justifyContent: "center", padding: "20px" }}>
        <Col span={15}>
          <IcoForm
            clients={clients}
            document={location.state?.document}
          ></IcoForm>
          <BackTo to={"/ico"}></BackTo>
        </Col>
      </Row>
    </>
  );
};

export default CreateIco;
