import React from "react";
import { Divider } from "antd";

export default function BottomPageTitle(props) {
  return (
    <Divider
      children={props?.title}
      style={{ position: "fixed", bottom: "15px", justifyContent: "center" }}
    ></Divider>
  );
}
