import {
  getDocForExport,
  getDocIcoForExport,
} from "../../db/document-functions/document-functions";
import dayjs from "dayjs";

export const createOrdersXML = (document, download = false) => {
  // Створення XML-даних як строки
  const productsString = getProductsString(document.products);
  const xmlDoc = `
        <document id="${document.id}">
            <comment>${document?.comment ? document.comment : ""}</comment>
            <client>${document.client}</client>
            <clientName>${document.clientName}</clientName>
            <priceType>${document.priceType}</priceType>
            <storage>${document.storage}</storage>
            <sum>${document.sum}</sum>
            <firm>${document.firm}</firm>
            <coordinates>
                <latitude>${document.coordinates?.latitude}</latitude>
                <longitude>${document.coordinates?.longitude}</longitude>
                <altitude>${document.coordinates?.altitude}</altitude>
                <speed>${document.coordinates?.speed}</speed>
                <heading/>
                <accuracy>${document.coordinates?.accuracy}</accuracy>
                <altitudeAccuracy/>
            </coordinates>
            <dateTime>${document.dateTime}</dateTime>
            <products>${productsString}</products>
            <documentId>${document.documentId}</documentId>
        </document>`;

  if (download)
    return downloadEvent(xmlDoc, getFileNameToSave(document.dateTime));
  return xmlDoc;
};

const getProductsString = (products) => {
  let res = "";
  products.forEach((element) => {
    res =
      res +
      `<product>
                    <guid>${element.guid}</guid>
                    <qty>${element.qty}</qty>
                    <price>${element.price}</price>
                </product>
                `;
  });
  return res;
};

const downloadEvent = (xmlDoc, fileName) => {
  // Створення Blob-об'єкта з XML-даних
  const blob = new Blob([xmlDoc], { type: "application/xml" });

  // Створення посилання для завантаження
  const link = window.document.createElement("a");
  link.href = URL.createObjectURL(blob);

  link.download = fileName
    ? fileName + ".xml"
    : dayjs().format("DD-MM-YYYY HH:mm:ss") + ".xml";

  // Симуляція кліку на посиланні для завантаження файлу
  link.click();

  // Вивільнення пам'яті
  URL.revokeObjectURL(link.href);
};

export const createOrdersXMLFromDocumentsArray = async (documents) => {
  let res = "";
  await Promise.all(
    documents.map(async (document) => {
      const docToDownload = await getDocForExport(document);
      const doxXml = createOrdersXML(docToDownload);
      res = res + doxXml;
      return res;
    })
  );
  downloadEvent(res, undefined);
};

const getFileNameToSave = (dateTime) => {
  let fileName = dateTime.split(" ").join("-");
  fileName = fileName.split(".").join("-");
  fileName = fileName.split(":").join("-");
  return fileName;
};

export const createIcoXML = (document, download = false) => {
  // Створення XML-даних як строки
  const xmlDoc = `
        <document id="${document.id}">
            <comment>${document?.comment ? document.comment : ""}</comment>
            <id>${document.id}</id>
            <documentId>${document.documentId}</documentId>
            <client>${document.client}</client>
            <clientName>${document.clientName}</clientName>
            <sourceDocGuid>${document?.sourceDocGuid}</sourceDocGuid>
            <sum>${document.sum}</sum>
            <dateTime>${document.dateTime}</dateTime>          
        </document>`;

  if (download)
    return downloadEvent(xmlDoc, getFileNameToSave(document.dateTime));
  return xmlDoc;
};

export const createIcosXMLFromDocumentsArray = async (documents) => {
  let res = "";
  await Promise.all(
    documents.map(async (document) => {
      const docToDownload = await getDocIcoForExport(document);
      const doxXml = createIcoXML(docToDownload);
      res = res + doxXml;
      return res;
    })
  );
  downloadEvent(res, undefined);
};
