import { db } from "../../db/dexie-db/db";
import { message } from "antd";
import dayjs from "dayjs";
import { sortArrayOfObjectsByTimeAdded } from "../../utils/arrayFunctions";

// const db = openDataBase();

export const getRecord = async (objectStore, value) => {
  try {
    return await db[objectStore].get(value);
  } catch (error) {
    return false;
  }
};

export const getRecordByKeyValue = async (objectStore, keyValueObject) => {
  try {
    return await db[objectStore].get(keyValueObject);
  } catch (error) {
    message.error("Помилка отримання даних з " + objectStore);
    return false;
  }
};

export const getAllRecords = async (objectStore) => {
  try {
    return await db[objectStore].toArray();
  } catch (error) {
    message.error("[ERROR] - failed to get products from " + objectStore);
    return false;
  }
};

export const getRecords = async (objectStore, index, value) => {
  try {
    return await db[objectStore].where(index).equals(value).toArray();
  } catch (error) {
    return false;
  }
};

export const getRecordsByKeywords = async (objectStore, index, value) => {
  try {
    const collection = db[objectStore].filter((record) =>
      record[index].toLowerCase().includes(value.toLowerCase())
    );
    return await collection.toArray();
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteRecord = async (objectStore, indexField) => {
  try {
    await db[objectStore].delete(indexField);
    return true;
  } catch (error) {
    message.error("Помилка видалення з " + objectStore);
    return false;
  }
};

export const clearObjectStore = async (objectStore) => {
  try {
    await db[objectStore].clear();
    return true;
  } catch (error) {
    message.error("Помилка очищення " + objectStore);
    return false;
  }
};

export const updateRecord = async (objectStore, key, record) => {
  try {
    await db[objectStore].update(key, record);
    return true;
  } catch (error) {
    message.error("Помилка оновлення " + objectStore);
    console.log(error);
    return false;
  }
};

export const updateRecords = async (objectStore, records) => {
  try {
    await Promise.all(
      records.map(async (record) => {
        return updateRecord(objectStore, record.id, record);
      })
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const addRecord = async (objectStore, record) => {
  try {
    await db[objectStore].add(record);
    return true;
  } catch (error) {
    console.log(error);
    message.error("Помилка при записі у " + objectStore);
    return false;
  }
};

export const bulkAdd = async (objectStore, records) => {
  try {
    await db[objectStore].bulkPut(records);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getProductToTable = async (product, currentDocumentTable) => {
  const existingProduct = await getRecord(currentDocumentTable, product.guid);
  const productToReturn = { ...product };
  productToReturn.timeAdded = +dayjs().valueOf().toString();
  productToReturn.key = productToReturn.timeAdded;
  if (existingProduct) {
    const existingProductQty = +existingProduct.qty ? +existingProduct.qty : 0;
    productToReturn.qty = existingProductQty + product.qty;
    productToReturn.qtyFromSource = existingProduct?.qtyFromSource;
    //не перезаписувати ціну існуючого товару
    productToReturn.price = existingProduct?.price;
  }
  return { productToReturn, existingProduct: existingProduct && true };
};

export const addProductToIndexDb2 = async (product, exists, dbTable) => {
  if (exists) {
    const updated = await updateRecord(dbTable, product.guid, product);
    if (!updated) {
      message.error("Помилка оновлення товару у документі!");
      return false;
    }
    return true;
  }
  const added = await addRecord(dbTable, product);
  if (!added) {
    message.error("Помилка додавання товару " + product.title);
    return false;
  }
  return true;
};

export const getProductsWithNew = (product, products, exists) => {
  if (exists) {
    const productsWithoutCurrent = products.filter(
      (prod) => prod.guid !== product.guid
    );
    const newProducts = [...productsWithoutCurrent, product];
    return newProducts;
  }
  const newProducts = [...products, product];
  return newProducts;
};

export const getSortedProductsByTimeAdded = (products) => {
  return products.sort(sortArrayOfObjectsByTimeAdded);
};

export const getProductsWithIndexes = (products) => {
  return products.map((product, ind) => {
    const { index, ...productWithoutIndex } = product;
    return { index: ind + 1, ...productWithoutIndex };
  });
};

export async function exportDatabase() {
  const exportData = {};

  // Отримуємо всі назви таблиць
  const tableNames = db.tables.map((table) => table.name);

  // Для кожної таблиці отримуємо всі дані та додаємо в об'єкт exportData
  for (const tableName of tableNames) {
    const tableData = await db.table(tableName).toArray();
    exportData[tableName] = tableData;
  }

  // Конвертуємо об'єкт у JSON-формат
  const jsonData = JSON.stringify(exportData, null, 2);

  // Створюємо файл для завантаження
  const blob = new Blob([jsonData], { type: "application/json" });
  const url = URL.createObjectURL(blob);

  // Створюємо посилання для завантаження файлу
  const a = document.createElement("a");
  a.href = url;
  a.download = "database_export.json";
  a.click();

  // Очищення URL об'єкта
  URL.revokeObjectURL(url);
}
