import React from "react";
import { useNavigate } from "react-router";
import { Modal, List } from "antd";

import { FileAddOutlined } from "@ant-design/icons";
export const ModalActions = (props) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = (e, record) => {
    console.log(record);
    switch (record.key) {
      case "createIco":
        navigate("/ico/create", { state: { client: props.client.guid } });
        // navigate(`/ico/create`);
        break;
      case "createOrder":
        navigate("/orders/create", { state: { client: props.client.guid } });
        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Створити ПКО",
      key: "createIco",
      icon: <FileAddOutlined />,
    },
    {
      title: " Cтворити замовлення",
      key: "createOrder",
      icon: <FileAddOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={props.client.name}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item onClick={(e) => handleMenuClick(e, item)}>
              <List.Item.Meta avatar={item.icon} title={item.title} />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
export default ModalActions;
