export function listToTree(list) {
  const map = {};
  const roots = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].code] = i;
    list[i].children = [];
  }

  let node;

  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    node.key = node.code;
    if (node.parentCode !== "") {
      list[map[node.parentCode]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
}
