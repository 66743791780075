export const getGeolocation = (
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled
) => {
  if (!isGeolocationEnabled) {
    return {
      status: false,
      message: "Геолокація вимкнена! Увімкніть геолокацію і повторіть спробу!",
    };
  }
  if (!isGeolocationAvailable) {
    return {
      status: false,
      message: "Браузер не підтримує геолокацію!",
    };
  }
  if (!coords) {
    return {
      status: false,
      message: "Не вдалося отримати координати...",
    };
  }

  return {
    status: true,
    coords: {
      latitude: coords.latitude,
      longitude: coords.longitude,
      altitude: coords.altitude,
      speed: coords.speed,
      heading: coords.heading,
      accuracy: coords.accuracy,
      altitudeAccuracy: coords.altitudeAccuracy,
    },
  };
};
