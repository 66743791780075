import React from "react";
import { Button, DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

export const DateFilterDropDown = (props) => {
  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };
  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  return (
    <>
      <DatePicker.RangePicker
        value={props.selectedKeys[0]}
        onChange={(e) => {
          props.setSelectedKeys(e ? [e] : []);
        }}
      ></DatePicker.RangePicker>

      <br></br>
      <Button
        type="primary"
        onClick={() => {
          props.confirm();
        }}
      >
        Фільтр
      </Button>
      <Button
        type="danger"
        onClick={() => {
          props.clearFilters();
          props.confirm();
        }}
      >
        Скинути
      </Button>
    </>
  );
};
