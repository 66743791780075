import { Modal, Space, Image } from "antd";
import React from "react";

export default function ModalInstallPWA(props) {
  return (
    <>
      <Modal
        open={props.open}
        onOk={props.handleInstall}
        onCancel={props.handleClose}
        title={props.title}
        okText={"Встановити"}
      >
        <Space>
          {!!props.logo && <Image preview={false} src={props.logo}></Image>}
          {!!props.description && <>{props.description}</>}
        </Space>
      </Modal>
    </>
  );
}
