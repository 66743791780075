import React from "react";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

export default function BackTo(props) {
  const navigate = useNavigate();
  const goTo = () => navigate(props?.to);

  return (
    <FloatButton
      size="large"
      type="primary"
      style={{ position: "fixed", bottom: "20px", left: "24px" }}
      onClick={goTo}
      icon={<RollbackOutlined />}
    ></FloatButton>
  );
}
