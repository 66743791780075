export const isDevelopment = () => {
  if (process.env.NODE_ENV === "development") {
    return true;
  }
  return false;
};

export const isProduction = () => {
  if (process.env.NODE_ENV === "production") {
    return true;
  }
  return false;
};

export const getServer = () => {
  if (isDevelopment()) {
    // return "https://ta.waapp.com.ua";
    return "http://localhost:8000";
  }
  return "";
};
