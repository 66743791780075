import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getDocumentId } from "../../db/document-functions/document-functions";
import { addRecord, getRecords } from "../../db/dexie-db/functions";
import { TABLES } from "../../_CONSTANTS/constants";
import { useAuth } from "../../hook/useAuth";

export default function ModalCreateIco(props) {
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [clientsInSelect, setClientsInSelect] = useState([]);

  const handleCancel = () => {
    props.setIsModalCreateIcoOpen(false);
  };
  const onFinish = async (values) => {
    const sourceDoc = props.documentSource;

    const sourceDocDebt = sourceDoc?.docSum
      ? sourceDoc?.docSum
      : sourceDoc.docDebt;

    const sum = values.sumIco;
    const comment = values.comment;
    const date = dayjs().format("DD.MM.YY");

    const docsIcoInCurrentDebt = await getRecords(
      TABLES.ICO,
      "sourceDocGuid",
      sourceDoc.docGuid
    );

    const icosByOrderSum = getIcosByOrderSum(docsIcoInCurrentDebt);
    const resPaySum = +icosByOrderSum + sum;
    console.log(resPaySum);
    if (resPaySum > Math.abs(sourceDocDebt)) {
      let errorMessage = sourceDoc.docName
        ? "Сума оплати перевищує суму боргу! По документу " +
          sourceDoc.docName +
          " вже оплачено " +
          icosByOrderSum
        : "Сума оплати перевищує суму боргу по документу. Вже оплачено " +
          icosByOrderSum;

      return message.error(errorMessage, 5);
    }
    const ico = {
      clientGuid: values.client,
      sum,
      date,
      comment,
      created: dayjs(),
      modified: dayjs(),
      exported: "false",
      sourceDocGuid: props.documentSource?.docGuid
        ? props.documentSource?.docGuid
        : props.documentSource?.documentId,
      documentId: getDocumentId(user.id),
    };

    props.setIsModalCreateIcoOpen(false);
    try {
      await addRecord(TABLES.ICO, ico);
      message.success("Документ збережено!");
    } catch (error) {
      console.log(error);
      message.error("Помилка збереження документа");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Заповніть коректно поля!");
  };

  const getIcosByOrderSum = (documents) => {
    if (!documents) return 0;
    const sum = documents.reduce(
      (totalAndQnt, doc) => {
        return {
          total: totalAndQnt.total + Number(doc.sum),
          quantity: totalAndQnt.quantity + 1,
        };
      },
      {
        total: 0,
        quantity: 0,
      }
    );
    return sum.total.toFixed(2);
  };

  useEffect(() => {
    if (props.isModalCreateIcoOpen && props.documentSource) {
      const currentClient = {
        label: props.documentSource?.clientName,
        value: props.documentSource?.clientGuid,
      };
      setClientsInSelect([currentClient]);
      form.setFieldsValue({
        dateTimeNow: dayjs(),
        sumIco: Math.abs(props.documentSource?.docDebt),
        client: props.documentSource?.clientGuid,
      });
    }
  }, [props.isModalCreateIcoOpen]);

  return (
    <>
      <Modal
        title={
          props.documentSource?.docName
            ? props.documentSource?.docName
            : "Новий ПКО"
        }
        open={props.isModalCreateIcoOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
        >
          <Form.Item name="dateTimeNow">
            <DatePicker
              style={{ width: "100%" }}
              showTime
              size="large"
              disabled={true}
              format={"DD-MM-YYYY HH:mm:ss"}
            />
          </Form.Item>
          <Form.Item name="client">
            <Row>
              <Col span={24}>
                <Select
                  size="large"
                  disabled={true}
                  value={props.documentSource?.clientGuid}
                  placeholder="Контрагент"
                  optionFilterProp="children"
                  options={clientsInSelect}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="sumIco">
            <InputNumber
              //   ref={input1Ref}
              style={{ width: "100%" }}
              parser={(value) => value.replace(",", ".")}
              precision={2}
              size="large"
              addonAfter="грн"
              type={"tel"}
              required={true}
            />
          </Form.Item>
          <Form.Item name="comment">
            <Input
              size="large"
              allowClear={true}
              style={{ width: "100%" }}
              placeholder="Примітка"
              // onChange={onChangeComment}
            />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
