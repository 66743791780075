import { message } from "antd";
import { db } from "../../db/dexie-db/db";
import { deleteRecord, getRecord, getRecords } from "../dexie-db/functions";
import { ACTIONS, TABLES } from "../../_CONSTANTS/constants";

export async function addRecordToIndexedDb(record, add) {
  try {
    console.log(record);
    const added = await add(record);
    return added;
  } catch (error) {
    console.log("[ERROR] Failed to add product");
    throw error;
  }
}

export const getRecordByMyltipleKey = async (objectStore, value) => {
  try {
    const res = await db[objectStore]
      .where("barcodes")
      .equals(value)
      .distinct()
      .toArray();
    return res[0];
  } catch (error) {
    return false;
  }
};

export const getProductsByFiltersIds = async (filtersIds) => {
  try {
    const res = await db.Products.where("filtersIds")
      .anyOf(filtersIds)
      .distinct()
      .toArray();
    return res;
  } catch (error) {
    return false;
  }
};

export const updateRecordInIndexedDb = async (record, update) => {
  try {
    const updated = await update(record);
    return updated;
  } catch (error) {
    console.log("[ERROR] Failed to update product ");
    message.error("Помилка оновлення документа");
    throw error;
  }
};

export const clearCurrentDocument = async (deleteAll) => {
  try {
    // existingProduct.qty += product.qty;
    const deleted = await deleteAll();
    return deleted;
  } catch (error) {
    console.log("[ERROR] Failed to delete data");
    throw error;
  }
};

export async function saveDocumentToIndexedDb({
  document,
  addDocumentToIndexedDb,
  deleteProductsFromCurrentDocument,
}) {
  const added = await addRecordToIndexedDb(document, addDocumentToIndexedDb);
  if (!added) {
    return {
      status: false,
      message: "Помилка збереження документа",
    };
  }
  const cleared = await clearCurrentDocument(deleteProductsFromCurrentDocument);
  if (!cleared) {
    return {
      status: false,
      message: "Документ збережено, помилка очищення поточного документа",
    };
  }

  return { status: true };
}

export const getRecordsFromIndexedDb = async (getAll) => {
  try {
    // existingProduct.qty += product.qty;
    const records = await getAll();
    return records;
  } catch (error) {
    console.log("[ERROR] Failed to get records");
    throw error;
  }
};

export const getRecordFromIndexedDb = async (getRecord, value, indexSearch) => {
  try {
    // existingProduct.qty += product.qty;
    const record = await getRecord(indexSearch, value);
    return record;
  } catch (error) {
    message.error("Помилка отримання запису по " + value);
    console.log("[ERROR] Failed to get record");
    throw error;
  }
};

export const deleteRecordFromIndexedDb = async (deleteRecord, value) => {
  try {
    // existingProduct.qty += product.qty;
    const record = await deleteRecord(value);
    return record;
  } catch (error) {
    message.error("Помилка видалення запису");
    console.log("[ERROR] Failed to delete record");
    throw error;
  }
};

export const updateProductInArray = (product, productsToTable) => {
  const updatedProductToTableIndex = productsToTable.findIndex(
    (obj) => obj.guid === product.guid
  );
  const newProductsToTable = productsToTable;
  if (updatedProductToTableIndex !== -1) {
    newProductsToTable[updatedProductToTableIndex].qty = 0;
    newProductsToTable[updatedProductToTableIndex].enteredQty = 0;
    newProductsToTable[updatedProductToTableIndex].alreadyEntered = false;
  }
  return newProductsToTable;
};

export const getDocumentProductsWithoutDeleted = async (
  products,
  product,
  dbTable,
  action
) => {
  if (action === ACTIONS.CREATE || (action === ACTIONS.EDIT && dbTable)) {
    const deleted = await deleteRecord(dbTable, product.guid);
    if (!deleted) {
      message.error("Помилка видалення записів");
      return false;
    }
  }
  const newProducts = products.filter((prod) => prod.id !== product.id);
  return getProductsWithIndexes(newProducts);
};

export const getProductsWithIndexes = (products) => {
  return products.map((product, ind) => {
    const { index, ...productWithoutIndex } = product;
    return { index: ind + 1, ...productWithoutIndex };
  });
};

export const getProductsToTable = async (
  productsFromCategory,
  priceTypeGuid,
  dbTable
) => {
  return await Promise.all(
    productsFromCategory.map(async (product, index) => {
      const productPrices = await getRecords(
        TABLES.PRICE,
        "productGuid",
        product.guid
      );

      const currentOrderEnteredProduct = await getRecord(dbTable, product.guid);
      product.enteredQty = currentOrderEnteredProduct
        ? currentOrderEnteredProduct.qty
        : 0;

      // const editedOrderProducts = props?.document?.products;
      // const editedOrderEnteredProduct = editedOrderProducts?.find(
      //   (editedOrderProduct) => editedOrderProduct.guid === product.guid
      // );

      const alreadyEntered =
        // editedOrderEnteredProduct ||
        currentOrderEnteredProduct ? true : false;

      const price = productPrices.find(
        (price) => price.priceTypeGuid === priceTypeGuid
      );
      product.price = price?.priceValue === undefined ? 0 : price?.priceValue;
      product.alreadyEntered = alreadyEntered;
      return product;
    })
  );
};
