export const getVideoDevicesInfo = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter((device) => device.kind === "videoinput");

  const devicesId = videoDevices.map((device) => {
    return device.deviceId;
  });
  // console.log(devicesId.join("."));
  return devicesId.join(".");
};

export const checkDeviceSupport = () => {
  if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
    return true;
  } else {
    return false;
  }
};
