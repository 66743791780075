import React, { useState, useEffect } from "react";
import BottomPageTitle from "../components/header/BottomPageTitle";
import DebtsTable from "../components/debts/DebtsTable";
import BackButton from "../components/global/BackButton";
import { getAllRecords } from "../db/dexie-db/functions";
import { TABLES } from "../_CONSTANTS/constants";

export default function Debtspage() {
  const [debts, setDebts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataBefore = async () => {
      const debts = await getAllRecords(TABLES.DEBTS);
      const debtsWithClientName = await gatherClients(debts.sort(compare));
      setDebts(debtsWithClientName);
      setLoading(false);
    };
    getDataBefore();
  }, []);

  function compare(a, b) {
    if (a.clientName < b.clientName) {
      return -1;
    }
    if (a.clientName > b.clientName) {
      return 1;
    }
    return 0;
  }

  const gatherClients = async (debtsObj) => {
    const clientsFromDb = await getAllRecords(TABLES.CLIENTS);
    return debtsObj.map((debt, index) => {
      const client = clientsFromDb.find(
        (clientFromDb) => clientFromDb.guid === debt.clientGuid
      );
      return {
        ...debt,
        clientName: client.name,
        key: debt.clientGuid,
        index: index + 1,
        debtValue: debt.clientDebt,
      };
    });
  };

  return (
    <>
      <DebtsTable loading={loading} debts={debts}></DebtsTable>
      <BottomPageTitle title="Борги"></BottomPageTitle>
      <BackButton></BackButton>
    </>
  );
}
