import { getServer } from "../utils/getEnvironment";

const server = getServer();

const url = server + "/server/server.php";

export async function statistics(user, serviceName) {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        username: user.username,
        company: user.company_name,
        serviceName,
        action: "statistics",
      }),
    });
    const res = await result.json();

    return res;
  } catch (error) {}
}

export async function sendTGMessage(message) {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ message, action: "sendTGMessage" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
    // throw error;
    // return false;
  }
}
