import React from "react";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

export default function BackButton() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <FloatButton
      size="large"
      type="primary"
      style={{ position: "fixed", bottom: "20px", left: "24px" }}
      onClick={goBack}
      icon={<RollbackOutlined />}
    ></FloatButton>
  );
}
