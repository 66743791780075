import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useContext,
} from "react";
import { platforms, getPlatform } from "../utils/Platforms";
import ModalInstallPWA from "../components/app/ModalInstallPWA";

const ReactPWAInstallContext = createContext(Promise.reject);

export const useReactPWAInstall = () => useContext(ReactPWAInstallContext);

export const ReactPWAInstallProvider = ({ children, enableLogging }) => {
  function logger(message) {
    if (enableLogging) {
      console.log(message);
    }
  }

  function installed() {
    if (
      window.navigator.standalone === true ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      logger("isInstalled: true. Already in standalone mode");
      return true;
    }
    logger("isInstalled: false.");
    return false;
  }

  const platform = getPlatform();
  // console.log(platform);
  // console.log(installed());

  const awaitingPromiseRef = useRef();
  const deferredprompt = useRef(null);
  //   const [dialogState, setDialogState] = useState(null);
  const [isModalInstallAppOpen, setIsModalInstallAppOpen] = useState(false);

  const [contextValue, setContextValue] = useState({
    supported: supported,
    installed: installed,
    // pwaInstall: openDialog,
  });

  const getInstalledApps = async () => {
    if ("getInstalledRelatedApps" in window.navigator) {
      const relatedApps = await navigator.getInstalledRelatedApps();
      relatedApps.forEach((app) => {
        console.log(app.id, app.platform, app.url);
      });
    }
  };

  useEffect(() => {
    getInstalledApps();
    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPromptEvent
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPromptEvent
      );
    };
  }, [handleBeforeInstallPromptEvent]);

  function supported() {
    if (deferredprompt.current != null && platform === platforms.NATIVE) {
      logger("supported: true - native platform");
      return true;
    }
    if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
      logger("supported: true - manual support");
      return true;
    }
    logger("supported: false");
    return false;
  }

  async function handleBeforeInstallPromptEvent(event) {
    event.preventDefault();
    deferredprompt.current = event;
    logger("beforeinstallprompt event fired and captured");

    const isInstalled = installed();
    const isSupported = supported();
    // console.log(isSupported);

    if (isInstalled) return alert("Вже встановлено");
    if (isSupported && !isInstalled) await openDialog();
    // setIsModalInstallAppOpen(true);

    // setContextValue({
    //   supported: supported,
    //   isInstalled: isInstalled,
    // });
  }

  function openDialog() {
    // setDialogState(options);
    setIsModalInstallAppOpen(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }

  function handleClose() {
    setIsModalInstallAppOpen(false);
    // setDialogState(null);
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
  }

  function handleInstall() {
    logger("handleInstall called");
    setIsModalInstallAppOpen(false);
    if (deferredprompt.current != null) {
      return deferredprompt.current
        .prompt()
        .then((event) => deferredprompt.current.userChoice)
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            logger("PWA native installation succesful");
            if (awaitingPromiseRef.current) {
              awaitingPromiseRef.current.resolve();
            }
          } else {
            logger("User opted out by cancelling native installation");
            if (awaitingPromiseRef.current) {
              awaitingPromiseRef.current.reject();
            }
          }
        })
        .catch((err) => {
          if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
          }
          logger("Error occurred in the installing process: ", err);
        });
    } else {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
      }
    }
  }

  return (
    <>
      {/* <a href="http://localhost:3000" target="_blank">
        Open PWA
      </a> */}
      <ReactPWAInstallContext.Provider
        value={contextValue}
        children={children}
      />
      <ModalInstallPWA
        title={"Потрібно встановити додаток"}
        description={
          "Для зручнішого користування сервісом WAAPP рекомендуємо встановити мобільний додаток"
        }
        open={isModalInstallAppOpen}
        handleInstall={handleInstall}
        handleClose={handleClose}
        platform={platform}
        logo={"/waapp-logo-dark-150.png"}
      ></ModalInstallPWA>
    </>
  );
};

export default ReactPWAInstallProvider;
