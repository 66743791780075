import { DOCUMENTS_TYPES } from "../../../_CONSTANTS/constants";
import { statistics } from "../../../statistics/statistics";
import { getServer } from "../../../utils/getEnvironment";
import { addDocumentRecordToSql } from "../../Admin/api";
const server = getServer();
const url = server + "/server/server.php";

export const exportDocument = async (docObj, docType, user) => {
  if (!navigator.onLine) {
    return { status: "failed", message: "Немає інтернету!" };
  }

  let fileName = docObj.dateTime.split(" ").join("-");
  fileName = fileName.split(".").join("-");
  fileName = fileName.split(":").join("-");

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      docType,
      username: user.username,
      doc: docObj,
      fileName,
      action: "exportDocument",
    }),
  });

  if (docType === DOCUMENTS_TYPES.ORDER) {
    const response = await addDocumentRecordToSql(docObj, user.id);
    // statistics(user, "Замовлення");
  }
  try {
    const res = await result.json();
    statistics(user, docType);
    return res;
  } catch (error) {
    console.log(error);
    return { status: "failed", message: error };
  }
};
