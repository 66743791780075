import React from "react";
import { Table } from "antd";
import { getRecord } from "../../db/dexie-db/functions";
import { getOrderColumns } from "../documents/orders/table/columns";

const Products = (props) => {
  async function onRowClick(record) {
    try {
      const currentOrderEnteredProduct = await getRecord(
        props.dbTable,
        record.guid
      );

      const editedOrderEnteredProduct = props?.products.find(
        (editedOrderProduct) => editedOrderProduct.guid === record.guid
      );

      let alreadyEntered = {};
      if (currentOrderEnteredProduct) {
        alreadyEntered = currentOrderEnteredProduct;
      } else {
        alreadyEntered = editedOrderEnteredProduct;
      }

      const enteredQuantity = Number(alreadyEntered.qty);
      if (enteredQuantity) {
        record.qty = enteredQuantity;
      }
    } catch (error) {
      // console.log(error);
    }
    const product = record;

    props.setProductKey({ product });
    props.setIsModalOpenEnterQty(true);
  }

  return (
    <>
      <Table
        size={"small"}
        loading={props.loading}
        pagination={{
          position: ["bottomCenter"],
          pageSize: props.disablePagination ? 9999 : 10,
          hideOnSinglePage: true,
        }}
        scroll={{
          // x: "max-content",
          y: window.screen.height - 200,
        }}
        onRow={(record) => {
          return {
            onClick: () => onRowClick(record),
          };
        }}
        rowClassName={(record) => {
          return record.alreadyEntered ? "alreadyEntered" : "";
        }}
        columns={getOrderColumns(props)}
        dataSource={props.productsToTable}
        tableLayout="fixed"
      />
    </>
  );
};

export default Products;
