import React, { useRef, useState } from "react";
import { Button, Col, Divider, Input, Row, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Clients = (props) => {
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        props.onChangeClient(record.guid);
        props.setIsModalChooseClientOpen(false);
      },
    };
  };

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      width: "10%",
      sorter: (a, b) => a.index - b.index,
      defaultSortOder: "ascend",
    },
    {
      title: "Назва",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      key: "name",
      width: "35%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Адреса",
      dataIndex: "address",
      sorter: (a, b) => {
        return a.address.localeCompare(b.address);
      },
      render: (_, record) => {
        return (
          <>
            {record.address}
            {record?.phone ? (
              <>
                <Divider style={{ margin: 0 }}></Divider>
                {record?.phone}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
      key: "address",
      width: "45%",
      ...getColumnSearchProps("address"),
    },
    // {
    //   title: "Борг",
    //   dataIndex: "clientDebt",
    //   sorter: (a, b) => {
    //     return a.clientDebt.localeCompare(b.clientDebt);
    //   },
    //   key: "clientDebt",
    //   width: "20%",
    // },
  ];

  return (
    <>
      <Row style={{ justifyContent: "center" }}>
        <Col span={24}>
          <Table
            size={"small"}
            loading={props.loading}
            pagination={{
              position: ["bottomCenter"],
              pageSize: props.disablePagination ? 9999 : 10,
              hideOnSinglePage: true,
            }}
            onRow={onRowAction}
            columns={columns}
            dataSource={props.clientsToTable}
            tableLayout="fixed"
            // onChange={onChange}
          />
        </Col>
        {/* <ModalActions
          setIsModalActionsOpen={setIsModalActionsOpen}
          isModalActionsOpen={isModalActionsOpen}
          client={client}
        ></ModalActions> */}
      </Row>
    </>
  );
};

export default Clients;
