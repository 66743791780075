import React, { useEffect, useState } from "react";
import Categories from "./Categories";
import Products from "./Products";
import { Modal, Tabs, FloatButton } from "antd";
import {
  PlusCircleOutlined,
  DatabaseOutlined,
  OrderedListOutlined,
  SettingOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import BarcodeInput from "./BarcodeInput";
import ModalEnterQty from "../documents/orders/create/ModalEnterQty";
import Settings from "./Settings";
import { TABLES } from "../../_CONSTANTS/constants";
import { getAllRecords } from "../../db/dexie-db/functions";

const ChooseProducts = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeKey, setActiveKey] = useState();
  const [loading, setLoading] = useState(true);
  const [isCheckedShowProductsByStock, setIsCheckedShowProductsByStock] =
    useState(localStorage.getItem("showProductsByStock") === "true" ?? true);
  const [isCheckedDisablePagination, setIsCheckedDisablePagination] = useState(
    localStorage.getItem("disablePagination") === "true" ?? true
  );
  const [isModalOpenEnterQty, setIsModalOpenEnterQty] = useState(false);
  const [productKey, setProductKey] = useState({});
  const [
    isCheckedProductsFromPrevOrdersOnly,
    setIsCheckedProductsFromPrevOrdersOnly,
  ] = useState(false);
  const [filters, setFilters] = useState([]);
  const [showBarcodesInTree, setShowBarcodesInTree] = useState(
    localStorage.getItem("showBarcodesInTree") === "true" ?? true
  );

  useEffect(() => {
    setFiltersData();
  }, []);

  // useEffect(() => {
  //   console.log(productsToTable);
  //   console.log(props.products);
  //   const productsInDocument = props.products;

  //   const newProductsToTable = productsInDocument.map((productInDoc) => {
  //     const productInTable = productsToTable.find(
  //       (prod) => prod.guid === productInDoc.guid
  //     );
  //     return productInTable
  //       ? { ...productInTable, enteredQty: productInDoc.qty }
  //       : { ...productInTable, enteredQty: 0 };
  //   });

  //   setProductsToTable(newProductsToTable);
  // }, [isModalOpen]);

  const setFiltersData = async () => {
    const filters = await getAllRecords(TABLES.FILTERS);

    if (!filters?.length) return;
    setFilters(
      filters.map((filter) => {
        return { ...filter, checked: false };
      })
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const changeKey = (key) => {
    setActiveKey(key);
  };

  const onChangeShowByStock = (value) => {
    localStorage.setItem("showProductsByStock", value);
    setIsCheckedShowProductsByStock(value);
  };

  const onChangeDisablePagination = (value) => {
    localStorage.setItem("disablePagination", value);
    setIsCheckedDisablePagination(value);
  };

  const onChangeShowBarcodesInTree = (value) => {
    localStorage.setItem("showBarcodesInTree", value);
    setShowBarcodesInTree(value);
  };

  const items = [
    {
      label: (
        <span>
          <DatabaseOutlined />
          Категорії
        </span>
      ),
      key: "1",
      children: (
        <Categories
          priceTypeGuid={props.priceType}
          setProductsToTable={props.setProductsToTable}
          setLoading={setLoading}
          setActiveKey={setActiveKey}
          showProductsByStock={isCheckedShowProductsByStock}
          setIsCheckedProductsFromPrevOrdersOnly={
            setIsCheckedProductsFromPrevOrdersOnly
          }
          filters={filters}
          setFilters={setFilters}
          dbTable={props.dbTable}
        ></Categories>
      ),
    },
    {
      label: (
        <span>
          <OrderedListOutlined />
          Товари
        </span>
      ),
      key: "2",
      children: (
        <Products
          action={props.action}
          dbTable={props.dbTable}
          loading={loading}
          productsToTable={props.productsToTable}
          products={props.products}
          setProducts={props.setProducts}
          disablePagination={isCheckedDisablePagination}
          setProductKey={setProductKey}
          setIsModalOpenEnterQty={setIsModalOpenEnterQty}
          isModalOpenEnterQty={isModalOpenEnterQty}
          showBarcodesInTree={showBarcodesInTree}
        ></Products>
      ),
    },
    {
      label: (
        <span>
          <BarcodeOutlined />
        </span>
      ),
      key: "3",
      children: (
        <BarcodeInput
          priceTypeGuid={props.priceType}
          priceType={props.priceType}
          action={props.action}
          dbTable={props.dbTable}
          loading={loading}
          productsToTable={props.productsToTable}
          products={props.products}
          setProducts={props.setProducts}
          disablePagination={isCheckedDisablePagination}
          setProductKey={setProductKey}
          setIsModalOpenEnterQty={setIsModalOpenEnterQty}
          isModalOpenEnterQty={isModalOpenEnterQty}
          activeKey={activeKey}
        ></BarcodeInput>
      ),
    },
    {
      label: <SettingOutlined />,
      key: "4",
      children: (
        <Settings
          isCheckedShowProductsByStock={isCheckedShowProductsByStock}
          onChangeShowByStock={onChangeShowByStock}
          isCheckedDisablePagination={isCheckedDisablePagination}
          onChangeDisablePagination={onChangeDisablePagination}
          setProductsToTable={props.setProductsToTable}
          client={props.client}
          setLoading={setLoading}
          isCheckedProductsFromPrevOrdersOnly={
            isCheckedProductsFromPrevOrdersOnly
          }
          setIsCheckedProductsFromPrevOrdersOnly={
            setIsCheckedProductsFromPrevOrdersOnly
          }
          productsToTable={props.productsToTable}
          priceTypeGuid={props.priceType}
          // activeKey={activeKey}
          filters={filters}
          setFilters={setFilters}
          onChangeShowBarcodesInTree={onChangeShowBarcodesInTree}
          showBarcodesInTree={showBarcodesInTree}
        ></Settings>
      ),
    },
  ];

  return (
    <>
      <FloatButton
        size="large"
        type="primary"
        style={{ position: "fixed", bottom: "20px", right: "24px" }}
        onClick={showModal}
        icon={<PlusCircleOutlined />}
      ></FloatButton>
      <Modal
        style={{ top: 10 }}
        title="Підбір товару"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={changeKey}
          items={items}
        ></Tabs>
      </Modal>
      <ModalEnterQty
        setIsModalOpenEnterQty={setIsModalOpenEnterQty}
        isModalOpenEnterQty={isModalOpenEnterQty}
        productKey={productKey}
        products={props.products}
        setProducts={props.setProducts}
        action={props.action}
        dbTable={props.dbTable}
        setProductKey={setProductKey}
        setProductsToTable={props.setProductsToTable}
        productsToTable={props.productsToTable}
      />
    </>
  );
};

export default ChooseProducts;
