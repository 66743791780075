import dayjs from "dayjs";

export function removeDuplicatesFromArray(arr) {
  return arr.filter((it, index) => index === arr.indexOf((it = it.trim())));
}

export function compareByTitle(a, b) {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

export function sortArrayOfObjectsByTimeAdded(a, b) {
  if (a.timeAdded < b.timeAdded) {
    return -1;
  }
  if (a.timeAdded > b.timeAdded) {
    return 1;
  }
  return 0;
}

export function getUniqueArrayOfObjects(arr, field) {
  const uniqueIds = [];
  const uniqueArr = [];
  arr.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[field]);
    if (!isDuplicate) {
      uniqueIds.push(element.guid);
      uniqueArr.push(element);
      return true;
    }
    return false;
  });

  return uniqueArr;
}

export const sortArrayOfObjectsByDateField = (arr, field) => {
  const sortedArray = arr.sort(function (a, b) {
    const dateA = dayjs(a[field]);
    const dateB = dayjs(b[field]);
    const result = dateA - dateB;
    return result;
  });

  return sortedArray;
};

export const sortArrayOfObjectsByTitle = (a, b) => {
  return a.title.localeCompare(b.title);
};
