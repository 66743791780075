import React, { useEffect, useState } from "react";
import { Col, message, Row, Tree } from "antd";
import { listToTree } from "../../utils/list-to-tree";
import {
  getAllRecords,
  getRecord,
  getRecords,
} from "../../db/dexie-db/functions";
import { compareByTitle } from "../../utils/arrayFunctions";
import { setKeysAndIndexToArray } from "../../db/document-functions/document-functions";
import { TABLES } from "../../_CONSTANTS/constants";
const { DirectoryTree } = Tree;

const ClientCategories = (props) => {
  const [categories, setCategories] = useState();

  useEffect(() => {
    getParentCategories();
  }, []);

  async function getParentCategories() {
    const categories = await getAllRecords(TABLES.CLIENTS_CATEGORIES);
    const categoriesTree = listToTree(categories.sort(compareByTitle));

    setCategories(categoriesTree);
  }

  const onSelect = async (keys, info) => {};

  const onExpand = async (keys, info) => {
    if (!info.expanded) {
      return;
    }
    let key = keys.slice(-1)[0];

    let clientsFromCategory = await getRecords(
      TABLES.CLIENTS,
      "parentCode",
      key
    );

    if (clientsFromCategory.length > 0) {
      const sortedClients = clientsFromCategory.sort(compareByTitle);
      const clientsWithKeys = setKeysAndIndexToArray(sortedClients);

      props.setClientsToTable(clientsWithKeys);
      props.setLoading(false);
      props.setActiveKey("2");
    } else {
      // message.info("В даній категорії немає товарів");
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <DirectoryTree
            style={{ textAlign: "left" }}
            multiple
            defaultExpandParent={false}
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={categories}
          />
        </Col>
      </Row>
    </>
  );
};

export default ClientCategories;
