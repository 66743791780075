import { Steps, Drawer, Spin, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { sortArrayOfObjectsByDateField } from "../utils/arrayFunctions";
import { calculateDistanceBetweenPoints } from "./api/utils";
import dayjs from "dayjs";

export default function RecordsSteps(props) {
  const [totalDistance, setTotalDistance] = useState(0);

  const [documentsInTable, setDocumentsInTable] = useState([]);

  useEffect(() => {
    if (props.documents.length) setDocuments(props.documents);
  }, [props.documents]);

  const setDocuments = async (documents) => {
    const sortedDocuments = sortArrayOfObjectsByDateField(
      documents,
      "date_time"
    );

    const docs = await Promise.all(
      sortedDocuments.map(async (doc, index) => {
        const resultDoc = {
          index: index + 1,
          clientName: doc.client_name,
          created: doc.date_time,
          documentId: doc.document_id,
          coordinates: doc?.coordinates,
        };

        const origin = documents[index]?.coordinates;
        const destination = documents[index + 1]?.coordinates;

        if (
          origin &&
          destination &&
          origin.lat !== 0 &&
          origin.lng !== 0 &&
          destination.lat !== 0 &&
          destination.lng !== 0
        ) {
          resultDoc.distance = await calculateDistanceBetweenPoints(
            origin,
            destination
          );

          setTotalDistance(totalDistance + resultDoc.distance.value);
        }

        return resultDoc;
      })
    );
    setDocumentsInTable(docs);
  };

  const getSteps = () => {
    const stepsItems = documentsInTable.map((record, index) => {
      return {
        status: "finish",
        title: `${
          index === 0 || index === documentsInTable.length - 1 ? "" : index
        }  ${record.clientName}: ${dayjs(record.created).format("HH:mm:ss")}`,
        description: record?.distance
          ? `${record?.distance?.text}`
          : index === documentsInTable.length - 1
          ? ""
          : "Немає даних",
      };
    });

    return stepsItems;
  };

  const getTotalDistance = () => {
    const totalDistance = documentsInTable.reduce(
      (totalAndQnt, doc) => {
        return {
          total:
            totalAndQnt.total +
            (Number(doc?.distance?.value) ? Number(doc?.distance?.value) : 0),
        };
      },
      {
        total: 0,
      }
    );
    return (totalDistance.total / 1000).toFixed(2);
  };

  return (
    <>
      <Drawer
        open={props.isRecordsTableOpen}
        onClose={() => props.setIsRecordsTableOpen(false)}
        mask={false}
        maskClosable={false}
        closable={false}
      >
        {props.isLoading ? (
          <Spin></Spin>
        ) : (
          <>
            <h6>{`Довжина маршруту: ${getTotalDistance()} км`}</h6>
            <Divider style={{ marginBottom: 0 }}></Divider>
            <Steps progressDot direction="vertical" items={getSteps()} />
          </>
        )}
      </Drawer>
    </>
  );
}
