import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, List, Col, Spin } from "antd";
import { FileAddOutlined } from "@ant-design/icons";

export const ModalActions = (props) => {
  //   const { user } = useAuth();
  const [isSpinning, setIsSpinning] = useState(false);
  const navigate = useNavigate();

  const document = props?.debtData?.document;
  const clientGuid = document?.clientGuid;
  //   const documentId = document?.id;

  //   console.log("awdawd", clientGuid);

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case "createIco":
        // navigate(`/ico/create`, {
        //   state: {
        //     document: {
        //       clientGuid,
        //       sum: Number(document.docDebt),
        //       docGuid: document.docGuid,
        //     },
        //     from: "/debts",
        //   },
        // });
        props.setIsModalActionsOpen(false);
        props.setIsModalCreateIcoOpen(true);
        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Створити ПКО",
      key: "createIco",
      icon: <FileAddOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={document?.docName}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
};
export default ModalActions;
