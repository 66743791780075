import { InfoWindowF, MarkerF, OverlayView } from "@react-google-maps/api";
import React from "react";

export default function MyMarker({ marker, onClickMarker }) {
  return (
    <>
      <MarkerF
        key={marker.key}
        onClick={() => onClickMarker(marker)}
        position={marker}
      ></MarkerF>
      {/* Заборонено закривати у стилях */}
      <InfoWindowF
        position={marker}
        anchor={marker}
        options={{ pixelOffset: new window.google.maps.Size(0, -35) }}
      >
        <>{marker.clientName}</>
      </InfoWindowF>
    </>
  );
}
