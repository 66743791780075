import React, { useEffect } from "react";
import "./App.css";
import AppRouter from "./router/AppRouter";
import { AuthProvider } from "./hoc/AuthProvider";
import { openDataBase } from "./db/dexie-db/db";
import ThemeProvider from "./hoc/ThemeProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPWAInstallProvider from "./hoc/ReactPWAInstallProvider";

function App() {
  const handleUnload = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  return (
    <>
      <ReactPWAInstallProvider
        children={
          <AuthProvider>
            <ThemeProvider>
              <AppRouter></AppRouter>
            </ThemeProvider>
          </AuthProvider>
        }
      ></ReactPWAInstallProvider>
    </>
  );
}

export default App;
