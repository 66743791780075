import { Table, message } from "antd";
import React, { useState } from "react";
import ChooseProducts from "../../../productsTree/chooseProducts";
import { TableTotals } from "./TableTotals";
import { getTableTotals } from "../../../../utils/table-totals";
import { updateRecord } from "../../../../db/dexie-db/functions";
import { ACTIONS, TABLES } from "../../../../_CONSTANTS/constants";
import ModalEditProductQty from "../../ModalEditProductQty";
import {
  getDocumentProductsWithoutDeleted,
  updateProductInArray,
} from "../../../../db/document-functions/functions";

const ProductsTab = (props) => {
  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const [productsToTable, setProductsToTable] = useState([]);

  const columns = [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        return record.index;
      },
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "40%",
      sorter: (a, b) => a.title - b.title,
      render: (_, record) => (
        <span
          style={
            record?.color && record?.color !== "0,0,0"
              ? { color: `rgb(${record.color})` }
              : {}
          }
        >
          {record.title}
        </span>
      ),
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "15%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "15%",
      render: (_, record) => {
        return (
          <span style={{ fontWeight: "500" }}>
            {record.isWeight ? record?.qty.toFixed(3) : parseInt(record?.qty)}
          </span>
        );
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "20%",
      render: (_, record) => {
        return Number(record.qty * record.price).toFixed(2);
      },
    },
  ];

  const handleChangeQty = async (record, value) => {
    const existingProduct = props.products.find(
      (product) => product.key === record.key
    );

    existingProduct.qty = value;
    existingProduct.sum = value * record.price;
    const newProducts = JSON.parse(JSON.stringify(props.products));

    const updated = await updateRecord(
      TABLES.CURRENT_ORDER,
      existingProduct.guid,
      existingProduct
    );
    if (updated) props.setProducts(newProducts);
  };

  const deleteItem = async (record) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      props.products,
      record,
      props.dbTable,
      props.action
    );

    if (!arrayWithoutDeletedProduct)
      return message.error("Помилка видалення товару з документу!");

    props.setProducts(arrayWithoutDeletedProduct);

    const updatedProductsForChoosing = updateProductInArray(
      record,
      productsToTable
    );
    setProductsToTable(updatedProductsForChoosing);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  return (
    <>
      <Table
        onRow={(record, rowIndex) => {
          if (props?.action === ACTIONS.VIEW) return;
          return {
            // onClick: (event) => {alert("onClick")}, // click row
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        columns={columns}
        dataSource={props.products}
        size={"small"}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        onChange={onChange}
        title={() => {
          const totals = getTableTotals(props.products);
          return (
            <TableTotals
              quantity={totals.quantity.toFixed(2)}
              total={totals.total.toFixed(2)}
              currency={props?.currency}
            />
          );
        }}
      />
      <ModalEditProductQty
        isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
        setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
        handleChangeQty={handleChangeQty}
        productToChangeQty={productToChangeQty}
        removeProductFromDocument={deleteItem}
      ></ModalEditProductQty>
      {props.action === ACTIONS.VIEW ? (
        ""
      ) : (
        <>
          <ChooseProducts
            priceType={props.priceType}
            products={props.products}
            setProducts={props.setProducts}
            dbTable={props.dbTable}
            action={props.action}
            client={props.client}
            productsToTable={productsToTable}
            setProductsToTable={setProductsToTable}
          />
        </>
      )}
    </>
  );
};

export { ProductsTab };
