import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Image,
  Row,
  Col,
  Divider,
  message,
  Space,
} from "antd";
import "../styles/login.css";
import { useAuth } from "../hook/useAuth";
import {
  checkloginByDeviceId,
  getStoredToken,
  loginNew,
} from "../api/login/Login";
import {
  getVideoDevicesInfo,
  checkDeviceSupport,
} from "../api/login/MediaDevicesInfo";
import AutoAuthModal from "../components/login/AutoAuthModal";
import dayjs from "dayjs";

export default function Login() {
  const passwordRef = useRef(null);
  const userNameRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [cameraDeviceId, setCameraDeviceId] = useState(null);
  const [autoUser, setAutoUser] = useState(null);

  const [isAutoAuthModalOpen, setIsAutoAuthModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const authSupport = checkDeviceSupport();
    if (authSupport) {
      checkCameraId();
    }
  }, []);

  const checkCameraId = async () => {
    const cameraId = await getVideoDevicesInfo();
    if (cameraId) {
      return setCameraDeviceId(cameraId);
    }
  };

  useEffect(() => {
    if (cameraDeviceId) {
      tryLoginByDeviceId();
    }
  }, [cameraDeviceId]);

  const { user, signIn } = useAuth();
  const fromPage = location.state?.from?.pathname || "/";
  if (user) {
    message.success("Ви вже авторизовані!");
    return <Navigate to={fromPage}></Navigate>;
  }

  const tryLoginByDeviceId = async () => {
    const availableLoginByDeviceid = await checkloginByDeviceId(cameraDeviceId);
    if (availableLoginByDeviceid.status === "success") {
      const user = await availableLoginByDeviceid.user;
      setAutoUser(user);
      setIsAutoAuthModalOpen(true);
    }
  };

  const onFinish = async (values) => {
    window.scrollTo(0, 0);
    userNameRef.current.blur();
    passwordRef.current.blur();

    setLoading(true);

    const username = values.username.toLowerCase();
    const password = values.password;
    const token = getStoredToken();

    try {
      const result = await loginNew(username, password, token, cameraDeviceId);
      if (result.status === "success") {
        const user = result.user;
        const userExpirationDate = dayjs(user.expiration_date);

        if (dayjs().isAfter(userExpirationDate)) {
          setLoading(false);
          return message.error(
            "Доступ заборонено, термін дії ліцензії закінчився!"
          );
        }
        signIn(user, () => navigate(fromPage, { replace: true }));
      } else {
        setLoading(false);
        return message.error(result.message, 5);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error(JSON.stringify(error));
      return message.error("Невідома помилка...", 5);
      //  window.location.reload();
    }
  };
  const onFinishFailed = (errorInfo) => {
    window.scrollTo(0, 0);
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="loginPage">
        <Row>
          <Col offset={2} span={20}>
            <Form
              className="loginForm"
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size="small"
              layout="vertical"
            >
              <Image
                style={{ width: "50%" }}
                preview={false}
                src={"/waapp-logo-light.svg"}
              ></Image>
              <Divider
                style={{
                  color: "white",
                  border: "white",
                  margin: "10px 0px",
                }}
              >
                TA - Авторизація
              </Divider>
              <Form.Item
                style={{ padding: "0px !important" }}
                label="Ім'я користувача"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Введіть ім'я користувача",
                  },
                ]}
              >
                <Input
                  ref={userNameRef}
                  size="large"
                  placeholder="Ім'я користувача"
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Введіть будь ласка пароль",
                  },
                ]}
              >
                <Input.Password
                  color="white"
                  size="large"
                  placeholder="Пароль"
                  allowClear
                  ref={passwordRef}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  style={{ width: "100%", border: "1px solid white" }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Вхід
                </Button>
              </Form.Item>
              {localStorage.getItem("showCameraAccessModal") === "false" ? (
                <Form.Item style={{ margin: "0px" }}>
                  <Divider
                    style={{ color: "white", border: "white" }}
                  ></Divider>
                </Form.Item>
              ) : null}
              <Divider
                style={{ color: "white", border: "white" }}
                children={
                  <>
                    <Space size={"small"}>
                      <Image
                        preview={false}
                        src={"/icons8-instagram.svg"}
                        onClick={() =>
                          (window.location =
                            "https://www.instagram.com/waapp.com.ua/")
                        }
                      ></Image>
                      <Image
                        preview={false}
                        src={"/icons8-facebook.svg"}
                        onClick={() =>
                          (window.location =
                            "https://www.facebook.com/waapp.com.ua")
                        }
                      ></Image>
                      <Image
                        preview={false}
                        src={"/icons8-tiktok.svg"}
                        onClick={() =>
                          (window.location =
                            "https://www.tiktok.com/@waapp.com.ua/")
                        }
                      ></Image>
                    </Space>
                  </>
                }
              ></Divider>
            </Form>
          </Col>
        </Row>
      </div>
      <AutoAuthModal
        user={autoUser}
        setIsAutoAuthModalOpen={setIsAutoAuthModalOpen}
        isAutoAuthModalOpen={isAutoAuthModalOpen}
        signIn={signIn}
      ></AutoAuthModal>
    </>
  );
}
