export const calculateDistanceBetweenPoints = async (origin, destination) => {
  const service = new window.google.maps.DistanceMatrixService();
  return new Promise((resolve) => {
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result) => {
        const distance = {
          text: result?.rows[0]?.elements[0].distance.text,
          value: result?.rows[0]?.elements[0].distance.value,
        };

        resolve(distance);
      }
    );
  });
};
