// відштовхуємось від того що цей ід 100% унікальний
// добавити кнопку "Це не мій користувач" - при натисканні надсилати унікальний ід і ім'я користувача собі у телеграм

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, message } from "antd";
import dayjs from "dayjs";
// import { sendTGMessage } from "../../statistics/statistics";

export default function AutoAuthModal(props) {
  const user = props?.user;
  const signIn = props?.signIn;
  const location = useLocation();
  const navigate = useNavigate();
  const fromPage = location.state?.from?.pathname || "/";

  const footer = [
    <Button type="primary" onClick={() => props?.setIsAutoAuthModalOpen(false)}>
      Скасувати
    </Button>,
    <Button
      type="primary"
      onClick={() => {
        const userExpirationDate = dayjs(user.expiration_date);

        if (dayjs().isAfter(userExpirationDate)) {
          return message.error(
            "Доступ заборонено, термін дії ліцензії закінчився!"
          );
        }
        // sendTGMessage(
        //   "Користувач " + user?.username + " зайшов через ід камери"
        // );
        signIn(user, () => navigate(fromPage, { replace: true }));
      }}
    >
      Увійти
    </Button>,
  ];

  return (
    <>
      <Modal
        title={"Автоматичний вхід"}
        open={props?.isAutoAuthModalOpen}
        onCancel={() => props?.setIsAutoAuthModalOpen(false)}
        footer={footer}
      >
        {"Користувач: " + user?.username + ", Компанія: " + user?.company}
      </Modal>
    </>
  );
}
