import React, { useState } from "react";
import {
  InputNumber,
  Modal,
  Space,
  Col,
  Row,
  message,
  Button,
  Popconfirm,
} from "antd";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  addProductToIndexDb2,
  getProductToTable,
  getProductsWithIndexes,
  getProductsWithNew,
  getSortedProductsByTimeAdded,
} from "../../../../db/dexie-db/functions";
import { ACTIONS } from "../../../../_CONSTANTS/constants";
import VirtualKeyboard from "../../../../components/virtual-keyboard/VirtualKeyboard";
import {
  getDocumentProductsWithoutDeleted,
  updateProductInArray,
} from "../../../../db/document-functions/functions";

export const ModalEnterQty = (props) => {
  const [productQty, setProductQty] = useState("");
  const [isVirtualKeyboardVisible, setIsVirtualKeyboardVisible] =
    useState(true);

  const product = props?.productKey.product;
  const enteredQty = product?.qty === undefined ? 0 : +product.qty;

  const handleOkEnterQty = async () => {
    const enteredQty = +productQty;
    if (!enteredQty) return message.error("Введіть число!");

    if (productQty === "") return props.setIsModalOpenEnterQty(false);

    product.qty = enteredQty;

    const productToTableStructure = await getProductToTable(
      product,
      props.dbTable
    );

    props.setIsModalOpenEnterQty(false);

    const updatedProductToTableIndex = props.productsToTable.findIndex(
      (obj) => obj.guid === product.guid
    );
    const newProductsToTable = props.productsToTable;

    if (updatedProductToTableIndex !== -1) {
      newProductsToTable[updatedProductToTableIndex].enteredQty =
        newProductsToTable[updatedProductToTableIndex].enteredQty + enteredQty;
      newProductsToTable[updatedProductToTableIndex].alreadyEntered = true;
    }
    props.setProductsToTable(newProductsToTable);
    return addProductToCurrentDocument(productToTableStructure, props.products);
  };

  const addProductToCurrentDocument = async (
    productToTableStructure,
    products
  ) => {
    // console.log(productToTableStructure);
    if (
      (props.action === ACTIONS.CREATE || props.action === ACTIONS.EDIT) &&
      props.dbTable
    ) {
      const addedToDb = await addProductToIndexDb2(
        productToTableStructure.productToReturn,
        productToTableStructure.existingProduct,
        props.dbTable
      );
      if (!addedToDb)
        return message.error("Помилка додавання товару у документ!");
    }
    const productsWithNew = getProductsWithNew(
      productToTableStructure.productToReturn,
      products,
      productToTableStructure.existingProduct
    );
    const sortedProducts = getSortedProductsByTimeAdded(productsWithNew);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    props.setProducts(sortedProductsWithIndexes);
    message.success("Додано", 2);
  };

  const handleCancelEnterQty = () => {
    props.setIsModalOpenEnterQty(false);
  };

  const removeProductFromDocument = async (productToRemove, products) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      productToRemove,
      props.dbTable,
      props.action
    );

    // const removedFromDb = await deleteRecord(TABLES.CURRENT_ORDER, record.guid);

    if (!arrayWithoutDeletedProduct)
      return message.error("Помилка видалення товару з документу!");

    // const productsWithotRemoved = products.filter(
    //   (prod) => prod.guid !== productToRemove.guid
    // );
    // const removedFromDb = await deleteRecord(
    //   TABLES.CURRENT_ORDER,
    //   productToRemove.guid
    // );
    // if (!removedFromDb)
    //   return message.error("Помилка видалення товару з документу!");

    const sortedProducts = getSortedProductsByTimeAdded(
      arrayWithoutDeletedProduct
    );
    // const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);

    props.setProducts(sortedProducts);
    // Дописати оновлення товару у підборі
    const updatedProductsForChoosing = updateProductInArray(
      product,
      props.productsToTable
    );
    message.success("Видалено!", 3);
    props.setProductKey({ product: { ...product, qty: undefined } });
    props.setProductsToTable(updatedProductsForChoosing);
    props.setIsModalOpenEnterQty(false);
  };

  const decrementQty = () => {
    if (productQty === 0 || productQty === "" || +productQty === 1) {
      setProductQty("1");
    } else {
      setProductQty(String(+productQty - 1));
    }
  };

  const incrementQty = () => {
    if (productQty === 0 || productQty === "") {
      setProductQty("1");
    } else {
      setProductQty(String(+productQty + 1));
    }
  };

  const onFocusQtyInput = () => {
    setIsVirtualKeyboardVisible(true);
  };

  const onBlurQtyInput = () => {
    setIsVirtualKeyboardVisible(false);
  };

  const modalTitle = product ? (
    <>
      <Row>
        <Col span={20}>
          <Space>{product.title}</Space>
          <br />
          <br />
          <Space>{"Ціна: " + product.price}</Space>
          <br />
          <Space>{"Залишок: " + product.stockQty}</Space>
          {enteredQty !== 0 ? (
            <>
              <br />
              <Space>
                {"Вже додано: " + enteredQty}
                {productQty === "" ? (
                  ""
                ) : (
                  <>
                    <ArrowRightOutlined />
                    {enteredQty + parseInt(productQty === "" ? 1 : +productQty)}
                  </>
                )}
              </Space>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  ) : (
    "Виберіть товар"
  );

  const footer = (
    <>
      <Row>
        <Col span={4} style={{ textAlign: "left" }}>
          {enteredQty !== 0 ? (
            <Popconfirm
              title={"Видалити позицію?"}
              onCancel={() => {
                return;
              }}
              onConfirm={() =>
                removeProductFromDocument(product, props.products)
              }
            >
              <Button icon={<DeleteOutlined />} size="large"></Button>
            </Popconfirm>
          ) : (
            ""
          )}
        </Col>
        <Col span={10}></Col>
        <Col span={10}>
          <Button
            style={{ width: "100%" }}
            key="submit"
            type="primary"
            onClick={handleOkEnterQty}
          >
            Додати
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <Modal
        title={modalTitle}
        open={props.isModalOpenEnterQty}
        onCancel={handleCancelEnterQty}
        styles={{ textAlign: "center" }}
        footer={footer}
        afterClose={() => {
          setProductQty("");
        }}
      >
        {/* <Space> */}
        <InputNumber
          parser={(value) => {
            if (product?.isWeight) {
              const valueFloat = parseFloat(value.replace(",", "."));
              return valueFloat;
            }
            const valueInt = parseInt(value.replace(",", "."));
            return valueInt;
          }}
          size={"large"}
          type={"tel"}
          disabled={true}
          onFocus={onFocusQtyInput}
          onBlur={onBlurQtyInput}
          onChange={(value) => {
            setProductQty(value);
          }}
          value={productQty}
          min={0.1}
          placeholder=""
          precision={2}
          addonBefore={
            <span style={{ padding: "10px" }} onClick={decrementQty}>
              -
            </span>
          }
          addonAfter={
            <span style={{ padding: "10px" }} onClick={incrementQty}>
              +
            </span>
          }
        ></InputNumber>
        {/* </Space> */}
      </Modal>
      <VirtualKeyboard
        isVirtualKeyboardVisible={props.isModalOpenEnterQty}
        setValue={setProductQty}
        value={productQty}
        onConfirm={handleOkEnterQty}
      ></VirtualKeyboard>
    </>
  );
};
export default ModalEnterQty;
