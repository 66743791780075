import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import EditIcoForm from "../../components/documents/incoming-cash-orders/edit-ico/EditForm";
import { message, Row, Col } from "antd";
import { getAllRecords } from "../../db/dexie-db/functions";
import BackTo from "../../components/global/BackTo";

export default function EditIco() {
  // const { getAll: getAllClients } = useIndexedDBStore("Clients");
  const params = useParams();
  const [clients, setClients] = useState([]);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    const getFieldsData = async () => {
      try {
        const clientsFromDb = await getAllRecords("Clients");
        const clients = clientsFromDb.map((client) => {
          return (client = {
            label: client.name,
            value: client.guid,
          });
        });
        setClients(clients);
      } catch (error) {
        message.error("Помилка отримання клієнтів");
      }
    };
    getFieldsData();
  }, []);

  return (
    <>
      <Row style={{ justifyContent: "center", padding: "20px" }}>
        <Col span={15}>
          <EditIcoForm clients={clients} documentId={params.id}></EditIcoForm>
          <BackTo to={"/ico"}></BackTo>
        </Col>
      </Row>
    </>
  );
}
