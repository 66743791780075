import React from "react";
import { DatePicker } from "antd";
import { ADMIN_DATE_FORMAT } from "../../_CONSTANTS/constants";
import dayjs from "dayjs";

export default function DateRangePicker(props) {
  const onChangeDates = (_dates, dateStrings) => {
    const dates = [dateStrings, dateStrings];
    props.setDates(dates);
  };

  return (
    <>
      <DatePicker
        style={{ width: "100%" }}
        defaultValue={dayjs(props.dates[0], ADMIN_DATE_FORMAT)}
        format={ADMIN_DATE_FORMAT}
        onChange={onChangeDates}
      />
    </>
  );
}
