import React, { useEffect, useState } from "react";
import { Progress, Modal, message } from "antd";
import { exportDocument } from "../../api/Export/xml/export";
import { getDocumentsForExport } from "../../db/document-functions/document-functions";
import { useAuth } from "../../hook/useAuth";

export default function ExportModal(props) {
  const { user } = useAuth();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (props.isModalExportOpen) {
      const documents = props.documents;
      exportData(documents);
    }
  }, [props.isModalExportOpen]);

  const exportData = async (todayDocuments) => {
    const todayDocumentsForExport = await getDocumentsForExport(
      todayDocuments,
      props.documentType
    );
    for (let index = 0; index < todayDocumentsForExport.length; index++) {
      const docForExport = todayDocumentsForExport[index];
      const result = await exportDocument(
        docForExport,
        props.documentType,
        user
      );
      if (result.status === "failed") {
        setProgress(0);
        props.setIsExportSuccess(false);
        props.setIsModalExportOpen(false);
        return message.error("Помилка експорту документу");
      }
      let persent = parseInt(
        (index + 1 * 100) / todayDocumentsForExport.length
      );
      setProgress(persent);
    }
    props.setIsExportSuccess(true);
    props.setIsModalExportOpen(false);
  };

  return (
    <>
      <Modal
        afterClose={props.afterExportFunction}
        title={"Експорт документів"}
        closable={true}
        footer={null}
        open={props.isModalExportOpen}
        onCancel={() => props.setIsModalExportOpen(false)}
      >
        <Progress
          style={{ width: "50%" }}
          percent={progress}
          format={(percent) => `${percent}% Відправлено`}
          // strokeColor={"#001529"}
          strokeLinecap={"butt"}
        />
      </Modal>
    </>
  );
}
