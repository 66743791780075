import React from "react";
import { Table } from "antd";
import { getOrderColumns } from "./orders/table/columns";

const FindedProductsTable = function FindedProductsTable(props) {
  const onRowClickHandler = (record) => {
    props.onRowClick(record);
  };

  return (
    <>
      <Table
        onRow={(record) => {
          return {
            onClick: () => onRowClickHandler(record),
            onContextMenu: () => props.onContextMenu(record),
          };
        }}
        columns={getOrderColumns(props)}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          hideOnSinglePage: true,
          pageSize: 999,
          // pageSizeOptions: [10, 100, 1000],
          responsive: true,
        }}
        rowClassName={(record) => {
          return record.alreadyEntered ? "alreadyEntered" : "";
        }}
        scroll={{
          // x: "max-content",
          y: window.screen.height - 300,
        }}
        dataSource={props?.findedProducts}
        size={"small"}
        loading={props?.isloading}
      />
    </>
  );
};

export default FindedProductsTable;
