import React from "react";
import { NumberKeyboard } from "antd-mobile";
import { playSound } from "../../utils/play-sound";
import sound from "../../UI/sound/button-50.mp3";

export default function VirtualKeyboard(props) {
  const onInputVirtualKeyboard = (value) => {
    props?.setValue((v) => (!v ? value : v + value));
    playSound(sound);
  };

  const onDeleteVirtualKeyboardDelete = () => {
    props?.setValue((v) => v.slice(0, v.length - 1));
  };

  return (
    <>
      <NumberKeyboard
        visible={props.isVirtualKeyboardVisible}
        showCloseButton={false}
        customKey={["."]}
        confirmText="OK"
        onInput={(value) => onInputVirtualKeyboard(value)}
        onConfirm={props.onConfirm}
        onDelete={onDeleteVirtualKeyboardDelete}
      />
    </>
  );
}
