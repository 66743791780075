import React, { useEffect } from "react";
import { getAgentsByCompany } from "../../api/Admin/api";
import { useAuth } from "../../hook/useAuth";
import { Select } from "antd";

export default function AgentsList(props) {
  const { adminUser } = useAuth();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const agents = await getAgentsByCompany(adminUser.company_id);
    const newAgents = agents.map((agent, index) => {
      return { ...agent, label: agent.username, value: agent.id, key: index };
    });
    props.setAgents(newAgents);
  };

  const onChange = (value) => {
    const selected = props.agents.find((agent) => agent.id === value);
    return props.setSelectedAgent(selected);
  };

  return (
    <>
      <Select
        allowClear
        style={{
          width: "100%",
        }}
        placeholder="Виберіть торгового"
        onChange={onChange}
        options={props.agents}
      />
    </>
  );
}
