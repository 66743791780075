import { Button, Col, message, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { SearchOutlined } from "@ant-design/icons";
import {
  searchProductInDb,
  searchProductInDbByTitle,
} from "../../db/document-functions/document-functions";
import { getRecord, getRecords } from "../../db/dexie-db/functions";
import { TABLES } from "../../_CONSTANTS/constants";
import FindedProductsTable from "../documents/FindedProductsTable";
import { sortArrayOfObjectsByTitle } from "../../utils/arrayFunctions";
import { getProductsToTable } from "../../db/document-functions/functions";

export default function BarcodeInput(props) {
  const inputDataRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleFindedProducts, setIsVisibleFindedProducts] = useState(false);
  const [findedProducts, setFindedProducts] = useState([]);

  useEffect(() => {
    if (props.activeKey === "3") {
      setTimeout(() => {
        inputDataRef.current.focus();
      }, 3);
    }
  }, [props.activeKey]);

  const enterHandler = (enteredData, products = props.products) => {
    if (!enteredData) {
      return message.info("Введіть дані");
    }
    processSearchProduct(enteredData, products);
  };
  const processSearchProductsByTitle = async (enteredData) => {
    setIsLoading(true);
    const products = await searchProductInDbByTitle(enteredData);

    if (!products) {
      setIsLoading(false);
      return processProductNotFound(enteredData);
    }

    const sortedByTitleProducts = products.sort(sortArrayOfObjectsByTitle);
    const productsToTable = await getProductsToTable(
      sortedByTitleProducts,
      props.priceTypeGuid,
      props.dbTable
    );
    setIsVisibleFindedProducts(true);
    setFindedProducts(productsToTable);

    setIsLoading(false);
  };

  const onRowClick = async (record) => {
    processFindedProduct(record);
    setFindedProducts([]);
    setIsVisibleFindedProducts(false);
    inputDataRef.current.focus();
  };

  const processSearchProduct = async (enteredData, products) => {
    if (!Number(enteredData)) {
      return processSearchProductsByTitle(enteredData);
    }

    const product = await searchProductInDb(enteredData);

    if (!product) {
      return processProductNotFound(enteredData);
    }
    return processFindedProduct(product, products);
  };

  const processProductNotFound = (enteredData) => {
    message.error("Товар " + enteredData + " не знайдено!");
    inputDataRef.current.focus();
  };

  const processFindedProduct = async (product, products) => {
    const productPrices = await getRecords(
      TABLES.PRICE,
      "productGuid",
      product.guid
    );

    const currentOrderEnteredProduct = await getRecord(
      TABLES.CURRENT_ORDER,
      product.guid
    );

    const editedOrderProducts = products;
    const editedOrderEnteredProduct = editedOrderProducts?.find(
      (editedOrderProduct) => editedOrderProduct.guid === product.guid
    );

    const alreadyEntered =
      editedOrderEnteredProduct || currentOrderEnteredProduct ? true : false;

    const price = productPrices.find(
      (price) => price.priceTypeGuid === props.priceType
    );
    product.price = price?.priceValue === undefined ? 0 : price?.priceValue;
    product.alreadyEntered = alreadyEntered;

    props.setProductKey({ product });
    props.setIsModalOpenEnterQty(true);
    // updateNewQtyDisplayData(product?.qty, product, products);
    // setCurrentProduct(product);
    // setModalTitle(product);
    // inputQtyRef.current.focus();
  };

  return (
    <>
      <Col span={18}>
        <form onSubmit={() => false}>
          <InputGroup className="mb-3">
            <Form.Control
              className="inputData"
              placeholder="Введіть дані"
              ref={inputDataRef}
              type={"text"}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  const enteredData = e.target.value;
                  e.target.value = "";
                  enterHandler(enteredData);
                }
              }}
              autoFocus={true}
            />
            <InputGroup.Text
              onClick={() => {
                const enteredData = inputDataRef.current.value;
                enterHandler(enteredData);
                inputDataRef.current.value = "";
              }}
            >
              <SearchOutlined />
            </InputGroup.Text>
          </InputGroup>
        </form>
      </Col>

      {isLoading ? <Spin spinning={isLoading}></Spin> : ""}
      {isVisibleFindedProducts && (
        <>
          <FindedProductsTable
            key="FindedProductsTable"
            findedProducts={findedProducts}
            onRowClick={onRowClick}
            isloading={isLoading}
          ></FindedProductsTable>
        </>
      )}
    </>
  );
}
