import { Table } from "antd";
import React from "react";

export default function StoragesTable(props) {
  const columns = [
    {
      title: "Назва",
      dataIndex: "name",
      width: "70%",
      sorter: (a, b) => a.name - b.name,
    },
    // {
    //   title: "Залишок",
    //   dataIndex: "qty",
    //   width: "15%",
    //   render: (_, record) => {
    //     return record.qty;
    //   },
    //   sorter: (a, b) => a.qty - b.qty,
    // },
  ];

  return (
    <>
      <Table
        size={"small"}
        loading={props.loading}
        pagination={{ position: ["bottomCenter"] }}
        columns={columns}
        dataSource={props.storages}
      />
    </>
  );
}
