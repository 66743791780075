import React from "react";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div style={{ padding: 20 }}>
      <p>Виникла помилка:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}
