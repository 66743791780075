import React, { useEffect, useState } from "react";
import StoragesTable from "../components/storages/StoragesTable";
import BottomPageTitle from "../components/header/BottomPageTitle";
import BackButton from "../components/global/BackButton";
import { getAllRecords } from "../db/dexie-db/functions";
import { message } from "antd";

export default function Storagespage() {
  // const { getAll: getAllStorages } = useIndexedDBStore("Storages");
  const [storages, setStorages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const storages = await getAllRecords("Storages");
        setStorages(storages);
        setLoading(false);
      } catch (error) {
        message.error("[ERROR] - failed to load storages");
      }
    };

    getData();
  }, []);
  return (
    <>
      <StoragesTable loading={loading} storages={storages}></StoragesTable>
      <BottomPageTitle title="Склади"></BottomPageTitle>
      <BackButton></BackButton>
    </>
  );
}
