import React from "react";
import { CloudOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";

export default function UpdateAvailableModal(props) {
  const footer = [
    <Button
      type="primary"
      onClick={() => props?.setIsOpenUpdateAvailableModal(false)}
    >
      Скасувати
    </Button>,
    <Button
      type="primary"
      onClick={() => {
        props?.setIsOpenUpdateAvailableModal(false);
        props.setIsOpenUpdateDataModal(true);
      }}
    >
      Оновити зараз
    </Button>,
  ];

  return (
    <>
      <Modal
        title={<>Оновлення</>}
        // style={{ textAlign: "center" }}
        open={props?.open}
        onCancel={() => props?.setIsOpenUpdateAvailableModal(false)}
        footer={footer}
      >
        <CloudOutlined></CloudOutlined> Доступне оновлення даних
      </Modal>
    </>
  );
}
