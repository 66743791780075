import { CheckOutlined } from "@ant-design/icons";

export const getOrderColumns = (props) => {
  return [
    {
      title: "Назва",
      dataIndex: "title",
      width: "50%",
      sorter: (a, b) => a.title - b.title,
      render: (_, record) => {
        if (props?.showBarcodesInTree && record.barcodes) {
          return (
            <span
              style={record?.color ? { color: `rgb(${record.color})` } : {}}
            >
              {record.title}
              <br></br>
              <i style={{ fontSize: "small" }}>
                {record.barcodes.map((barcode) => {
                  return (
                    <>
                      <span>{barcode}</span>
                      <br />
                    </>
                  );
                })}
              </i>
            </span>
          );
        }
        return (
          <span
            style={
              record?.color && record?.color !== "0,0,0"
                ? { color: `rgb(${record.color})` }
                : {}
            }
          >
            {record.title}
          </span>
        );
      },
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "25%",
      render: (_, record) => {
        return record.price.toFixed(2);
      },
    },
    {
      title: "Зал",
      dataIndex: "qty",
      width: "15%",
      render: (_, record) => {
        return record.stockQty;
      },
    },
    {
      title: <CheckOutlined />,
      dataIndex: "enteredQty",
      width: "15%",
      render: (_, record) => {
        return record.enteredQty ? (
          <strong>{record.enteredQty}</strong>
        ) : (
          record.enteredQty
        );
      },
      className: "enteredQty",
    },
  ];
};
