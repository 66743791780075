import {
  SearchOutlined,
  OrderedListOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import { useRef, useState } from "react";
import { Tree, Tabs } from "antd";
import Categories from "../productsTree/Categories";

export default function ProductsTable(props) {
  const [searchText, setSearchText] = useState("");
  const [activeKey, setActiveKey] = useState();
  const searchInput = useRef(null);
  // const [products, setProducts] = useState([]);
  const [productsToTable, setProductsToTable] = useState([]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Пошук`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Скинути
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      width: "10%",
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "70%",
      sorter: (a, b) => a.title - b.title,
      ...getColumnSearchProps("title"),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        props.setProductToModal(record);
        props.setIsModalInfoProductOpen(true);
      },
    };
  };

  const changeKey = (key) => {
    setActiveKey(key);
  };

  const deftabs = [
    {
      label: (
        <span>
          <DatabaseOutlined />
          Категорії
        </span>
      ),
      key: "1",
      children: (
        <Categories
          setProductsToTable={setProductsToTable}
          // loading={props.loading}
          setLoading={props.setLoading}
          setActiveKey={setActiveKey}
        ></Categories>
      ),
    },
    {
      label: (
        <span>
          <OrderedListOutlined />
          Товари
        </span>
      ),
      key: "2",
      children: (
        <Table
          size={"middle"}
          loading={props.loading}
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={productsToTable}
          onRow={onRowAction}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs
        // tabBarExtraContent={operations}
        defaultActiveKey="1"
        onChange={changeKey}
        activeKey={activeKey}
        // activeKey={}
        size={"small"}
        tabBarStyle={{ padding: "0px 10px" }}
        items={deftabs}
      />
    </>
  );
}
