import { message } from "antd";

import { getServer } from "../../../utils/getEnvironment";
const server = getServer();

const importUrl = "/server/server.php";

export async function importDataFromXml(username) {
  // Доробити перевірку якщо немає файлів або помилка
  const products = await getXmlFromServer(
    importUrl,
    username,
    "importProducts"
  );
  const categories = await getXmlFromServer(
    importUrl,
    username,
    "importCategories"
  );
  const clients = await getXmlFromServer(importUrl, username, "importClients");
  const clientsCategories = await getXmlFromServer(
    importUrl,
    username,
    "importClientsCategories"
  );
  const firms = await getXmlFromServer(importUrl, username, "importFirms");
  const price = await getXmlFromServer(importUrl, username, "importPrice");
  const priceTypes = await getXmlFromServer(
    importUrl,
    username,
    "importPriceTypes"
  );
  const stocks = await getXmlFromServer(importUrl, username, "importStocks");
  const storages = await getXmlFromServer(
    importUrl,
    username,
    "importStorages"
  );
  const debts = await getXmlFromServer(importUrl, username, "importDebts");
  const docProperties = await getXmlFromServer(
    importUrl,
    username,
    "importDocProperties"
  );

  const filters = await getXmlFromServer(importUrl, username, "importFilters");

  const data = {
    products,
    categories,
    clients,
    clientsCategories,
    firms,
    price,
    priceTypes,
    stocks,
    storages,
    debts,
    docProperties,
    filters,
  };

  return data;
}

export const importImages = async (username) => {
  try {
    const result = await fetch(server + importUrl, {
      method: "POST",
      body: JSON.stringify({ username, action: "importImages" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    message.error("[ERROR] - failed to get data from server");
    console.log(error);
    throw error;
    // return false;
  }
};

async function getXmlFromServer(url, username, action) {
  try {
    const result = await fetch(server + url, {
      method: "POST",
      body: JSON.stringify({ username, action }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    message.error("[ERROR] - failed to get data from server");
    console.log(error);
    // throw error;
    return false;
  }
}
