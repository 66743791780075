import React, { useState, useEffect } from "react";
import { Card, Col, Row, message, Collapse, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import ImportModal from "../components/import/ImportModal";
import { useAuth } from "../hook/useAuth";
import {
  OrderedListOutlined,
  PlusCircleOutlined,
  LogoutOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  CloudSyncOutlined,
  CalendarOutlined,
  MobileOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { getUpdateDate } from "../utils/getLatestUpdateDate";
import "../styles/home.css";
import SettingsModal from "../components/settings/SettingsModal";
import { getRecords, updateRecords } from "../db/dexie-db/functions";
import { exportDocument } from "../api/Export/xml/export";
import {
  deleteOldServicesDocuments,
  getDocumentsForExport,
  getTodayDocuments,
} from "../db/document-functions/document-functions";
import { LOCAL_STORAGE_FIELDS, TABLES } from "../_CONSTANTS/constants";

const MainPage2 = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [updatedDate, setUpdatedDate] = useState();
  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);
  const [isModalSettingsOpen, setIsModalSettingsOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const [todayOrdersData, setTodayOrdersData] = useState({});
  const [todayICOData, setTodayICOData] = useState({});

  useEffect(() => {
    setTodayStatistics();
    processDeleteOldDocuments();
  }, []);

  const processDeleteOldDocuments = async () => {
    const autoDeleteDocuments =
      localStorage.getItem(LOCAL_STORAGE_FIELDS.AUTO_DELETE_DOCUMENTS) ===
      "true";

    if (!autoDeleteDocuments) return;

    const daysForStoringDocuments = +localStorage.getItem(
      LOCAL_STORAGE_FIELDS.DAYS_FOR_STORING_DOCUMENTS
    );
    const daysForStoring = daysForStoringDocuments
      ? daysForStoringDocuments
      : 21;

    await deleteOldServicesDocuments(daysForStoring);
  };

  const setTodayStatistics = async () => {
    const todayOrdersData = await setTodayDocsStatistics(TABLES.ORDERS);
    setTodayOrdersData(todayOrdersData);

    const todayICOData = await setTodayDocsStatistics(TABLES.ICO);
    setTodayICOData(todayICOData);
  };

  const setTodayDocsStatistics = async (table) => {
    const todayDocs = await getTodayDocuments(table);
    // const todayOrdersQty = todayOrders ? todayOrders.length : 0;
    if (!todayDocs) return { total: 0, quantity: 0 };

    const todayDocsSumAndQty = todayDocs.reduce(
      (totalAndQnt, doc) => {
        return {
          total: totalAndQnt.total + Number(doc.sum),
          quantity: totalAndQnt.quantity + 1,
        };
      },
      {
        total: 0,
        quantity: 0,
      }
    );
    return todayDocsSumAndQty;
  };

  useEffect(() => {
    setUserInfo();
  }, [isOpenUpdateDataModal]);

  const userDataCards = {
    userCard: {
      title: (
        <>
          <UserOutlined /> Користувач: {user.username}
        </>
      ),
    },
    userDevicesCard: {
      title: (
        <>
          <MobileOutlined /> Вільних пристроїв:{" "}
          {Number(user.num_devices - user.token_used)}
        </>
      ),
    },
    userExpirationDateCard: {
      title: (
        <>
          <CalendarOutlined /> Доступ до:{" "}
          {dayjs(user.expiration_date).format("DD.MM.YYYY")}
        </>
      ),
    },
    userUpdateDataCard: {
      title: (
        <>
          <CloudSyncOutlined /> Оновлено: {updatedDate}
        </>
      ),
    },
  };

  const setUserInfo = () => {
    const updateDate = getUpdateDate();
    if (updateDate) {
      setUpdatedDate(updateDate);
    } else {
      setUpdatedDate("Невідомо");
    }
  };

  const exportData = async () => {
    setIsSpinning(true);
    const documentsTablesForExport = [
      { table: "Orders", docType: "order" },
      { table: "Ico", docType: "ico" },
    ];
    documentsTablesForExport.map(async (doc) => {
      const docs = await getRecords(doc.table, "exported", "false");
      const documentsForExport = await getDocumentsForExport(docs, doc.docType);

      if (documentsForExport.length > 0) {
        for (let index = 0; index < documentsForExport.length; index++) {
          const docForExport = documentsForExport[index];
          const result = await exportDocument(docForExport, doc.docType, user);
          if (result.status === "failed") {
            setIsSpinning(false);
            return message.error("Помилка експорту документу");
          }
          const exportedDocuments = docs.map((exportedDoc) => {
            return { ...exportedDoc, exported: "true" };
          });
          const updated = await updateRecords(doc.table, exportedDocuments);
          if (!updated) {
            setIsSpinning(false);
            return message.error("Помилка оновлення документів");
          }
        }
        setIsSpinning(false);
        message.success("Успішно!");
      } else {
        message.info("Немає документів для вигрузки");
        setIsSpinning(false);
      }
    });
  };

  const itemsCollapse = [
    {
      key: "1",
      label: "Інформація",
      children: (
        <>
          <Card
            title={userDataCards.userCard.title}
            extra={userDataCards.userCard.extra}
            size={"small"}
            bodyStyle={{ display: "none" }}
          ></Card>
          <Card
            title={userDataCards.userDevicesCard.title}
            size={"small"}
            bodyStyle={{ display: "none" }}
          ></Card>
          <Card
            title={userDataCards.userExpirationDateCard.title}
            size={"small"}
            bodyStyle={{ display: "none" }}
          ></Card>
          <Card
            title={userDataCards.userUpdateDataCard.title}
            size={"small"}
            bodyStyle={{ display: "none" }}
            extra={userDataCards.userUpdateDataCard.extra}
          ></Card>
        </>
      ),
    },
  ];

  return (
    <div className="site-card-wrapper">
      <Row style={{ padding: "10px 0px" }}>
        <Col span={12} style={{ padding: "0px 5px 0px 5px" }}>
          <Card
            title="Замовлення"
            bodyStyle={{ display: "none" }}
            actions={[
              <OrderedListOutlined
                className="homePageServiceIcon"
                onClick={() => navigate("orders")}
                key="ellipsis"
              />,
              <PlusCircleOutlined
                className="homePageServiceIcon"
                key="setting"
                onClick={() => navigate("orders/create")}
              />,
            ]}
          ></Card>
        </Col>
        <Col span={12} style={{ padding: "0px 5px 0px 5px" }}>
          <Card
            title="Приходи"
            bodyStyle={{ display: "none" }}
            actions={[
              <OrderedListOutlined
                className="homePageServiceIcon"
                onClick={() => navigate("invoice")}
                key="ellipsis"
              />,
              <PlusCircleOutlined
                className="homePageServiceIcon"
                key="setting"
                onClick={() => navigate("invoice/create")}
              />,
            ]}
          ></Card>
        </Col>
      </Row>
      <Row style={{ padding: "0px 0px" }}>
        <Col span={12} style={{ padding: "0px 5px 0px 5px" }}>
          <Card
            title="ПКО"
            bodyStyle={{ display: "none" }}
            actions={[
              <OrderedListOutlined
                className="homePageServiceIcon"
                onClick={() => navigate("ico")}
                key="ellipsis"
              />,
              <PlusCircleOutlined
                className="homePageServiceIcon"
                key="setting"
                onClick={() => navigate("ico/create")}
              />,
            ]}
          ></Card>
        </Col>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card
            title="Борги"
            bodyStyle={{ display: "none" }}
            actions={[
              <OrderedListOutlined
                className="homePageServiceIcon"
                onClick={() => navigate("debts")}
                key="ellipsis"
              />,
            ]}
          ></Card>
        </Col>
      </Row>

      <Row style={{ padding: "10px 0px" }}>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card
            title="Прайс лист"
            onClick={() => navigate("products")}
            bodyStyle={{ display: "none" }}
          ></Card>
        </Col>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card
            title="Довідники"
            bodyStyle={{ display: "none" }}
            onClick={() => navigate("storages")}
          ></Card>
        </Col>
      </Row>
      <Row justify={"center"}>
        {/* <Col span={24}>Статистика за 24.01.24</Col> */}
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card size="small">
            <Statistic
              title={`Замовлень: ${todayOrdersData.quantity}`}
              value={`Сума: ${todayOrdersData.total?.toFixed(2)}`}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card size="small">
            <Statistic
              title={`ПКО: ${todayICOData.quantity}`}
              value={`Сума: ${todayICOData.total?.toFixed(2)}`}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              // suffix="%"
            />
          </Card>
        </Col>
        {/* </Row> */}
        {/* <Col span={24} style={{ padding: "0px 5px 0px 5px" }}>
          <Collapse
            accordion
            size="small"
            defaultActiveKey={["1"]}
            items={itemsStatistics}
          ></Collapse>
        </Col> */}
      </Row>

      <Row justify={"center"} style={{ padding: "10px 0px 0px" }}>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card
            title={"Завантажити дані"}
            size={"small"}
            onClick={() => setIsOpenUpdateDataModal(true)}
            bodyStyle={{ display: "none" }}
            extra={<CloudDownloadOutlined />}
          ></Card>
        </Col>
        <Col span={12} style={{ padding: "0px 5px" }}>
          {isSpinning ? (
            <Card
              title={"Триває вигрузка... "}
              size={"small"}
              bodyStyle={{ display: "none" }}
              extra={<CloudUploadOutlined />}
            ></Card>
          ) : (
            <>
              <Card
                title={"Вивантажити дані "}
                size={"small"}
                bodyStyle={{ display: "none" }}
                extra={<CloudUploadOutlined />}
                onClick={exportData}
              ></Card>
            </>
          )}
        </Col>
      </Row>
      <Row justify={"center"} style={{ padding: "10px 0px 0px" }}>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card
            title={"Налаштування"}
            size={"small"}
            bodyStyle={{ display: "none" }}
            extra={<SettingOutlined />}
            onClick={() => setIsModalSettingsOpen(true)}
          ></Card>
        </Col>
        <Col span={12} style={{ padding: "0px 5px" }}>
          <Card
            title={"Вихід"}
            size={"small"}
            onClick={() => signOut(() => navigate("/"))}
            bodyStyle={{ display: "none" }}
            extra={<LogoutOutlined />}
          ></Card>
        </Col>
      </Row>
      <Row justify={"center"} style={{ padding: "10px 0px" }}>
        <Col span={24} style={{ padding: "0px 5px 0px 5px" }}>
          <Collapse accordion size="small" items={itemsCollapse}></Collapse>
        </Col>
      </Row>

      <ImportModal
        isOpenUpdateDataModal={isOpenUpdateDataModal}
        setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
      ></ImportModal>
      <SettingsModal
        isModalSettingsOpen={isModalSettingsOpen}
        setIsModalSettingsOpen={setIsModalSettingsOpen}
      ></SettingsModal>
    </div>
  );
};
export default MainPage2;
