import { LOCAL_STORAGE_FIELDS } from "../../_CONSTANTS/constants";

export const getDataFromLocalStorage = () => {
  const firmGuid = localStorage.getItem(LOCAL_STORAGE_FIELDS.CURRENT_FIRM);
  const clientGuid = localStorage.getItem(LOCAL_STORAGE_FIELDS.CURRENT_CLIENT);
  const storageGuid = localStorage.getItem(
    LOCAL_STORAGE_FIELDS.CURRENT_STORAGE
  );
  const defaultStorageGuid = localStorage.getItem(
    LOCAL_STORAGE_FIELDS.DEFAULT_STORAGE
  );
  const defaultFirmGuid = localStorage.getItem(
    LOCAL_STORAGE_FIELDS.DEFAULT_FIRM
  );

  const priceTypeGuid = localStorage.getItem(
    LOCAL_STORAGE_FIELDS.CURRENT_PRICE_TYPE
  );
  const comment = localStorage.getItem(LOCAL_STORAGE_FIELDS.CURRENT_COMMENT);

  return {
    comment,
    firmGuid: firmGuid ? firmGuid : defaultFirmGuid,
    clientGuid,
    storageGuid: storageGuid ? storageGuid : defaultStorageGuid,
    priceTypeGuid,
  };
};
