import React, { useEffect, useState } from "react";
import { MyButton } from "../../../../UI/myButton";
import { getRecord, updateRecord } from "../../../../db/dexie-db/functions";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import {
  InputNumber,
  DatePicker,
  Form,
  message,
  Select,
  Input,
  Row,
  Col,
  Button,
} from "antd";
import ModalChooseClient from "../../ModalChooseClient";
import dayjs from "dayjs";

const EditIcoForm = (props) => {
  const navigate = useNavigate();

  // const { update: updateDocument } = useIndexedDBStore("Ico");
  const [form] = Form.useForm();

  // const { getOneByKey: getClient } = useIndexedDBStore("Clients");
  // const { getOneByKey: getDocument } = useIndexedDBStore("Ico");

  // const [document, setDocument] = useState();
  const [isModalChooseClientOpen, setIsModalChooseClientOpen] = useState(false);
  const [client, setClient] = useState();

  useEffect(() => {
    const getDataBefore = async () => {
      const document = await getRecord("Ico", Number(props.documentId));
      setClient(document.clientGuid);
      form.setFieldsValue({
        dateTimeNow: dayjs(),
        client: document.clientGuid,
        sumIco: document.sum,
        comment: document.comment,
      });
    };

    getDataBefore();
  }, []);

  const onFinish = async (values) => {
    const clientGuid = values.client;
    const sum = values.sumIco;
    const comment = values.comment;

    const ico = {
      id: Number(props.documentId),
      clientGuid,
      sum,
      comment,
      modified: dayjs(),
      exported: "false",
    };
    try {
      // await updateDocumentInIndexedDb({ document: ico, updateDocument });
      await updateRecord("Ico", ico.id, ico);
      message.success("Документ збережено!");
      navigate("/ico", { replace: true });
    } catch (error) {
      console.log(error);
      message.error("Помилка збереження документа");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Заповніть коректно поля!");
  };
  const onChangeClient = async (value) => {
    setClient(value);
    form.setFieldsValue({
      client: value,
    });
  };

  return (
    <>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Form.Item name="dateTimeNow">
          <DatePicker
            style={{ width: "100%" }}
            showTime
            size="large"
            disabled={true}
            format={"DD-MM-YYYY HH:mm:ss"}
          />
        </Form.Item>
        <Form.Item name="client">
          <Row>
            <Col span={20}>
              <Select
                size="large"
                // onSelect={() => setInput1Focus()}
                style={{ width: "100%", textAlign: "left" }}
                // disabled={isDisabledClientField}
                showSearch
                onChange={onChangeClient}
                value={client}
                placeholder="Контрагент"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={props.clients}
              />
            </Col>
            <Col span={4}>
              <Button
                // disabled={isDisabledClientField}
                onClick={() => {
                  setIsModalChooseClientOpen(true);
                }}
                shape="default"
                size="large"
                icon={<SearchOutlined />}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="sumIco">
          <InputNumber
            style={{ width: "100%" }}
            parser={(value) => value.replace(",", ".")}
            precision={2}
            size="large"
            addonAfter="грн"
            type={"tel"}
            required={true}
          />
        </Form.Item>
        <Form.Item name="comment">
          <Input
            size="large"
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="Примітка"
            // onChange={onChangeComment}
          />
        </Form.Item>
        <Form.Item>
          <MyButton size="large" type="primary" htmlType="submit">
            Зберегти
          </MyButton>
        </Form.Item>
      </Form>
      <ModalChooseClient
        isModalChooseClientOpen={isModalChooseClientOpen}
        setIsModalChooseClientOpen={setIsModalChooseClientOpen}
        setClient={setClient}
      ></ModalChooseClient>
    </>
  );
};

export default EditIcoForm;
