import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRecord } from "../../db/dexie-db/functions";
import BackTo from "../../components/global/BackTo";
import { ACTIONS, TABLES } from "../../_CONSTANTS/constants";
import { OrderTabs } from "../../components/documents/orders/create/Tabs";
import BottomPageTitle from "../../components/header/BottomPageTitle";

const ViewOrder = () => {
  const params = useParams();
  const [document, setDocument] = useState();

  useEffect(() => {
    getDocOrder();
  }, []);

  const getDocOrder = async () => {
    const doc = await getRecord(TABLES.ORDERS, Number(params.id));
    setDocument(doc);
  };

  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "60px" }}>
        <Col span={24}>
          <OrderTabs document={document} action={ACTIONS.VIEW}></OrderTabs>
        </Col>
      </Row>
      <BottomPageTitle title="Перегляд замовлення"></BottomPageTitle>
      <BackTo to={"/orders"}></BackTo>
    </>
  );
};
export default ViewOrder;
