export const LOCAL_STORAGE_FIELDS = {
  CURRENT_STORAGE: "currentOrderStorage",
  DEFAULT_STORAGE: "defaultStorageGuid",
  DEFAULT_FIRM: "defaultFirmGuid",
  CURRENT_FIRM: "currentOrderFirm",
  CURRENT_CLIENT: "currentOrderClient",
  CURRENT_PRICE_TYPE: "currentOrderPriceType",
  CURRENT_COMMENT: "currentOrderComment",
  AUTO_DELETE_DOCUMENTS: "autoDeleteDocuments",
  DAYS_FOR_STORING_DOCUMENTS: "daysForStoringDocuments",
};

export const DOCUMENTS_TYPES = {
  ORDER: "order",
};

export const TABLES = {
  ORDERS: "Orders",
  ICO: "Ico",
  INVOICES: "Invoices",
  CURRENT_ORDER: "CurrentOrderProducts",
  EDITING_ORDER: "EditOrderProducts",
  CLIENTS: "Clients",
  CLIENTS_CATEGORIES: "ClientsCategories",
  STORAGES: "Storages",
  FIRMS: "Firms",
  PRICE_TYPES: "PriceTypes",
  CATEGORIES: "Categories",
  PRODUCTS: "Products",
  PRICE: "Price",
  DEBTS: "Debts",
  DOCUMENT_PROPERTIES: "DocProperties",
  FILTERS: "Filters",
};

export const ACTIONS = {
  CREATE: "create",
  EDIT: "edit",
  VIEW: "view",
};

export const GOOGLE_MAP_API_KEY = "AIzaSyA9Rt_CzrAqiVgVrEVNpKVjFAomSAxpxng";

export const ADMIN_DATE_FORMAT = "DD.MM.YYYY";
