import React, { useState, useEffect } from "react";
import { Tabs, Dropdown, message, Modal } from "antd";
import { ProductsTab } from "../create/ProductsTab";
import {
  DownOutlined,
  OrderedListOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import InfoTab from "./InfoTab";
import { useNavigate } from "react-router-dom";
import {
  addRecord,
  clearObjectStore,
  getAllRecords,
  getProductsWithIndexes,
  getRecordByKeyValue,
  getSortedProductsByTimeAdded,
  updateRecord,
} from "../../../../db/dexie-db/functions";
import { ACTIONS, TABLES } from "../../../../_CONSTANTS/constants";
import { clearLocalStorageCurrentOrder } from "../../../../db/localStorage/functions";
import { useGeolocated } from "react-geolocated";
import { getGeolocation } from "../../../../utils/geolocation";
import { getTableTotals } from "../../../../utils/table-totals";
import dayjs from "dayjs";
import { getDataFromLocalStorage } from "../../../../api/Storage/storage";
import { getDocumentId } from "../../../../db/document-functions/document-functions";
import { useAuth } from "../../../../hook/useAuth";
import ErrorFallback from "../../../ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";

const OrderTabs = (props) => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const document = props?.document;

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      watchPosition: true,
      userDecisionTimeout: 5000,
    });

  // const [disabledProductsTab, setDisabledProductsTab] = useState(true);
  const [activeKey, setActiveKey] = useState("1");

  const [firm, setFirm] = useState();
  const [storage, setStorage] = useState();
  const [priceType, setPriceType] = useState();
  const [currency, setCurrency] = useState();
  const [client, setClient] = useState();
  const [comment, setComment] = useState();
  const [products, setProducts] = useState();
  const [sum, setSum] = useState();
  const [docProperty, setDocProperty] = useState();

  useEffect(() => {
    if (props.action === ACTIONS.CREATE) {
      setDocumentData();
    } else {
      if (document) {
        setEditDocumentData(document);
      }
    }
  }, [document]);

  useEffect(() => {
    if (priceType) setPriceTypeCurrency(priceType);
  }, [priceType]);

  const setPriceTypeCurrency = async () => {
    const priceTypeObject = await getRecordByKeyValue(TABLES.PRICE_TYPES, {
      guid: priceType,
    });
    setCurrency(priceTypeObject.currency);
  };

  useEffect(() => {
    if (products) {
      const totals = getTableTotals(products);
      setSum(+totals.total.toFixed(2));
    }
  }, [products]);

  const setEditDocumentData = async (document) => {
    setProducts(document.products);
    setSum(document.sum);
    setClient(document.clientGuid);
    setFirm(document.firmGuid);
    setStorage(document.storageGuid);
    setPriceType(document.priceTypeGuid);
    setComment(document.comment);
    setDocProperty(document?.docProperty);
  };

  const setDocumentData = async () => {
    const products = await getAllRecords(TABLES.CURRENT_ORDER);
    const sortedProducts = getSortedProductsByTimeAdded(products);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    setProducts(sortedProductsWithIndexes);
    const docTotals = getTableTotals(products);
    setSum(docTotals.total);
    const initialValues = getDataFromLocalStorage();

    setClient(initialValues?.clientGuid);
    setFirm(initialValues?.firmGuid);
    setStorage(initialValues?.storageGuid);
    setPriceType(initialValues?.priceTypeGuid);
    setComment(initialValues?.comment);
    setDocProperty(initialValues?.docProperty);
  };

  function checkRequiredFilds() {
    if (!client || !priceType || !storage || !firm || !docProperty) {
      message.error("Заповніть всі поля");
      setActiveKey("1");
    } else {
      setActiveKey("2");
    }
  }

  const onTablClickFunction = (key) => {
    if (key == 2) {
      checkRequiredFilds();
    } else {
      setActiveKey("1");
    }
  };

  const getDocumentToSave = async () => {
    const geolocation = getGeolocation(
      coords,
      isGeolocationAvailable,
      isGeolocationEnabled
    );
    if (!geolocation.status) {
      message.error(geolocation.message);
    }
    const clientObj = await getRecordByKeyValue(TABLES.CLIENTS, {
      guid: client,
    });
    const doc = {
      coordinates: geolocation.coords,
      products,
      clientGuid: client,
      clientName: clientObj.name,
      firmGuid: firm,
      storageGuid: storage,
      priceTypeGuid: priceType,
      comment,
      sum: +getTableTotals(products).total.toFixed(2),
      date: dayjs().format("DD.MM.YY"),
      created: dayjs(),
      modified: dayjs(),
      exported: "false",
      documentId: getDocumentId(user.id),
      docPropertyGuid: docProperty?.value,
      docPropertyName: docProperty?.label,
    };
    return doc;
  };

  const handleMenuClick = (e) => {
    Modal.confirm({
      title: "Не зберігати документ?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        if (props.action === ACTIONS.CREATE) {
          await clearObjectStore(TABLES.CURRENT_ORDER);
          setTimeout(() => {
            clearLocalStorageCurrentOrder();
          }, 200);
          navigate("/orders");
        } else {
          await clearObjectStore(TABLES.EDITING_ORDER);
          navigate("/orders");
        }
      },
    });
    // console.log("click", e);
  };

  const processDocument = async () => {
    if (!products.length) {
      return message.error("Додайте товари");
    }
    if (props.action === ACTIONS.CREATE) {
      const doc = await getDocumentToSave();

      const saved = await addRecord(TABLES.ORDERS, doc);
      if (saved) {
        message.success("Замовлення збережено!");
        clearObjectStore(TABLES.CURRENT_ORDER);
        clearLocalStorageCurrentOrder();
        // дописати запис у таблицю для статистики
        navigate("/orders");
      }
    } else {
      // const updatedDocument = {
      //   ...document,
      //   updated: dayjs(),
      //   exported: "false",
      //   products,
      //   id: document.id,
      // };

      const documentToSave = await getDocumentToSave();
      const docToUpdate = {
        ...documentToSave,
        documentId: document.documentId,
        created: document.created,
        coordinates: document?.coordinates
          ? document.coordinates
          : documentToSave?.coordinates,
        modified: dayjs(),
        id: document.id,
      };
      const updated = await updateRecord(
        TABLES.ORDERS,
        Number(docToUpdate.id),
        docToUpdate
      );
      if (updated) {
        message.success("Замовлення оновлено!");
        clearObjectStore(TABLES.EDITING_ORDER);
        navigate("/orders");
      }
    }
  };

  const deftabs = [
    {
      label: (
        <span>
          <DatabaseOutlined />
          Інфо
        </span>
      ),
      key: "1",
      children: (
        <InfoTab
          disabledFields={props.action === ACTIONS.VIEW ? true : false}
          client={client}
          setClient={setClient}
          firm={firm}
          setFirm={setFirm}
          priceType={priceType}
          setPriceType={setPriceType}
          storage={storage}
          setStorage={setStorage}
          comment={comment}
          setComment={setComment}
          sum={sum}
          document={document}
          currency={currency}
          action={props.action}
          docProperty={docProperty}
          setDocProperty={setDocProperty}
        />
      ),
    },
    {
      label: (
        <span>
          <OrderedListOutlined />
          Товари
        </span>
      ),
      key: "2",
      children: (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ProductsTab
            disabledFields={props.action === ACTIONS.VIEW ? true : false}
            action={props.action}
            dbTable={props.dbTable}
            priceType={priceType}
            products={products}
            setProducts={setProducts}
            // productsToTable={props.productsToTable}
            // setProductsToTable={props.setProductsToTable}
            currency={currency}
            client={client}
          />
        </ErrorBoundary>
      ),
    },
  ];

  const items = [
    {
      label: "Скасувати",
      key: "1",
      onClick: handleMenuClick,
    },
  ];
  const operations = (
    <Dropdown.Button
      size="large"
      icon={<DownOutlined />}
      menu={{
        items,
      }}
      onClick={() => processDocument()}
    >
      Зберегти
    </Dropdown.Button>
  );

  return (
    <>
      <Tabs
        onChange={onTablClickFunction}
        tabBarExtraContent={props.action === ACTIONS.VIEW ? null : operations}
        defaultActiveKey="1"
        activeKey={activeKey}
        size={"large"}
        tabBarStyle={{ padding: "0px 10px", marginBottom: 0 }}
        items={deftabs}
      />
    </>
  );
};

export { OrderTabs };
