import React from "react";
import BackButton from "../components/global/BackButton";

const NoPage = () => {
  return (
    <>
      <h2>Сторінка ще не готова</h2>
      <BackButton></BackButton>
    </>
  );
};

export default NoPage;
