export const getNumberFromString = (stringNumber) => {
  let normalizedNumber = stringNumber;
  try {
    normalizedNumber = normalizedNumber.replace(/,/g, ".");
  } catch (error) {}
  try {
    normalizedNumber = normalizedNumber.split(" ").join("");
  } catch (error) {}
  try {
    normalizedNumber = normalizedNumber.split(" ").join("");
  } catch (error) {}
  return +normalizedNumber;
};

export function addHours(date, hours) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date;
}
