import React, { useEffect, useState } from "react";
import TableClients from "../components/clients/TableClients";
import BottomPageTitle from "../components/header/BottomPageTitle";
import BackButton from "../components/global/BackButton";
import { getRecordFromIndexedDb } from "../db/document-functions/functions";
import { getAllRecords, getRecord } from "../db/dexie-db/functions";

export default function Clientspage() {
  // const { getAll: getClients } = useIndexedDBStore("Clients");
  // const { getOneByKey: getDebt } = useIndexedDBStore("Debts");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataBefore = async () => {
      let clients = await getAllRecords("Clients");
      const clientsToTable = await Promise.all(
        clients.map(async (client, index) => {
          // const debtClient = await getRecordFromIndexedDb(
          //   getDebt,
          //   client.guid,
          //   "clientGuid"
          // );
          const debtClient = await getRecord("Debts", client.guid);
          client.clientDebt = debtClient?.debtValue ? debtClient.debtValue : 0;
          client.index = index + 1;
          client.key = client.id;
          return client;
        })
      );
      setClients(clientsToTable);
      setLoading(false);
    };
    getDataBefore();
  }, []);

  return (
    <>
      <TableClients loading={loading} clients={clients}></TableClients>
      <BottomPageTitle title="Клієнти"></BottomPageTitle>
      <BackButton></BackButton>
    </>
  );
}
