import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import { DateFilterDropDown } from "../../../filters/DateFilterDropDown";
import { SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Table, Input, Space, Button, Checkbox } from "antd";

import ModalActions from "./ModalActions";
import { TableTotals } from "../../orders/create/TableTotals";
import { getOrdersTotals } from "../../../../utils/table-totals";

const TableDocumentsIco = (props) => {
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);

  const [document, setDocument] = useState(null);

  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      width: "5%",
      sorter: (a, b) => a.index - b.index,
      defaultSortOrder: "descend",
    },
    {
      title: "Дата",
      dataIndex: "date",
      width: "15%",
      key: "date",
      render: (_, record) => {
        const date = dayjs(record.created.$d).format("DD.MM.YY HH:mm:ss");
        return date;
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <DateFilterDropDown
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        );
      },
      filterIcon: () => {
        return <SearchOutlined></SearchOutlined>;
      },
      onFilter: (value, record) => {
        return record.date
          ? dayjs(record.date).isBetween(value[0], value[1], "day", [])
          : "";
      },
    },
    {
      title: "Контр",
      dataIndex: "clientName",
      sorter: (a, b) => {
        return a.clientName.localeCompare(b.clientName);
      },
      key: "clientName",
      width: "45%",
      ...getColumnSearchProps("clientName"),
    },
    {
      title: "Сума",
      dataIndex: "sum",
      width: "20%",
      key: "sum",
      render: (_, record) => {
        return record.sum.toFixed(2);
      },
      sorter: (a, b) => a.sum - b.sum,
    },
    {
      title: <CloudUploadOutlined />,
      dataIndex: "exported",
      key: "exported",
      width: "10%",
      render: (_, record) => {
        return (
          <Checkbox
            disabled
            checked={record.exported === "true" ? true : false}
          ></Checkbox>
        );
      },
    },
  ];

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setIsModalActionsOpen(true);
        // setDocumentId(record.id);
        setDocument(record);
      },
    };
  };

  return (
    <>
      <Table
        loading={props.loading}
        onRow={onRowAction}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        columns={columns}
        dataSource={props.documents}
        title={() => {
          const totals = getOrdersTotals(props.documents);
          return (
            <TableTotals
              quantity={totals.quantity.toFixed(2)}
              total={totals.total.toFixed(2)}
              // currency={props?.currency}
            />
          );
        }}
      />
      <ModalActions
        document={document}
        setDocument={setDocument}
        setIsModalActionsOpen={setIsModalActionsOpen}
        isModalActionsOpen={isModalActionsOpen}
        documents={props.documents}
        setDocuments={props.setDocuments}
      ></ModalActions>
    </>
  );
};

export default TableDocumentsIco;
