import React, { useState } from "react";
import { message, Modal, List, Col, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hook/useAuth";
import { deleteRecord, updateRecord } from "../../../../db/dexie-db/functions";
import {
  getDocIcoForExport,
  getRecordsForTable,
} from "../../../../db/document-functions/document-functions";
import { exportDocument } from "../../../../api/Export/xml/export";
import { TABLES } from "../../../../_CONSTANTS/constants";
import { createIcoXML } from "../../../../api/Download/createXml";

export const ModalActions = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSpinning, setIsSpinning] = useState(false);

  const document = props?.document;
  const documentId = document?.id;
  const documentIndex = document?.index;

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case "download":
        setIsSpinning(true);
        const docForDownload = await getDocIcoForExport(props.document);
        createIcoXML(docForDownload, true);
        setIsSpinning(false);
        break;
      case "export":
        setIsSpinning(true);
        // const docFromDb = await getRecord("Ico", documentId);
        const docForExport = await getDocIcoForExport(document);
        const result = await exportDocument(docForExport, "ico", user);
        setIsSpinning(false);
        if (result.status === "failed") {
          return message.error("Помилка експорту документу. " + result.message);
        }
        message.success("Документ відправлено!");

        console.log();
        const newDoc = { ...document, exported: "true" };

        const updated = await updateRecord(TABLES.ICO, newDoc.id, newDoc);
        if (!updated) {
          message.error("Помилка оновлення статусу");
        }

        const newDocuments = props.documents.filter(
          (doc) => doc.id !== newDoc.id
        );
        props.setDocuments([...newDocuments, newDoc]);
        props.setIsModalActionsOpen(false);
        break;
      case "editDocument":
        navigate(`edit/${documentId}`);
        break;
      case "deleteDocument":
        Modal.confirm({
          title: "Видалити документ №" + documentIndex + "?",
          onCancel: () => {
            props.setIsModalActionsOpen(false);
            return;
          },
          onOk: async () => {
            const deleted = await deleteRecord(TABLES.ICO, document.id);
            if (deleted) {
              const newDocuments = props.documents.filter(
                (doc) => doc.id !== documentId
              );
              props.setDocuments(getRecordsForTable(newDocuments));
              props.setIsModalActionsOpen(false);
              message.success("Документ видалено!");
            }
          },
        });

        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Експортувати",
      key: "export",
      icon: <ExportOutlined />,
    },
    {
      title: " Завантажити",
      key: "download",
      icon: <DownloadOutlined />,
    },
    {
      title: " Змінити",
      key: "editDocument",
      icon: <EditOutlined />,
    },
    {
      title: " Видалити",
      key: "deleteDocument",
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={"Дії з документом № " + documentIndex}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
};
export default ModalActions;
