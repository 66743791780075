import { message } from "antd";

import { getServer } from "../utils/getEnvironment";
const server = getServer();

const checkUpdateUrl = server + "/server/server.php";

export async function isUpdateNeeded(latestUpdateDate, username) {
  try {
    const result = await fetch(checkUpdateUrl, {
      method: "POST",
      body: JSON.stringify({ username, action: "getLatestUpdateDateLocal" }),
    });
    const res = await result.json();
    if (!res) {
      return true;
    }

    return new Date(res) > new Date(latestUpdateDate) ? true : false;
  } catch (error) {
    message.error("Помилка перевірки необхідності оновлення");
    console.log(error);
    throw error;
    // return false;
  }
}
