import { Row, Col } from "antd";
import React from "react";
import BackTo from "../../components/global/BackTo";
import { OrderTabs } from "../../components/documents/orders/create/Tabs";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { ACTIONS, TABLES } from "../../_CONSTANTS/constants";

const CreateOrder = () => {
  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "60px" }}>
        <Col span={24}>
          <OrderTabs
            action={ACTIONS.CREATE}
            dbTable={TABLES.CURRENT_ORDER}
          ></OrderTabs>
        </Col>
      </Row>
      <BottomPageTitle title="Нове замовлення"></BottomPageTitle>
      <BackTo to={"/orders"}></BackTo>
    </>
  );
};
export default CreateOrder;
