import { Row, Col, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import {
  bulkAdd,
  clearObjectStore,
  getRecord,
} from "../../db/dexie-db/functions";
import BackTo from "../../components/global/BackTo";
import { ACTIONS, TABLES } from "../../_CONSTANTS/constants";
import { OrderTabs } from "../../components/documents/orders/create/Tabs";

const EditOrder = () => {
  const params = useParams();
  const [document, setDocument] = useState();

  useEffect(() => {
    getData();
  }, [params.id]);

  const getData = async () => {
    const document = await getRecord(TABLES.ORDERS, Number(params.id));
    if (document) {
      setDocument(document);
      processNewEditing(document);
    }
  };

  const processNewEditing = async (doc) => {
    const deleted = await clearObjectStore(TABLES.EDITING_ORDER);
    if (!deleted)
      return message.error("Помилка очищення таблиці для редагування!");

    const products = doc.products;
    const addedToIndexedDb = await bulkAdd(TABLES.EDITING_ORDER, products);
    if (!addedToIndexedDb) {
      return message.error("Помилка заповнення товарів документа");
    }
  };

  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "60px" }}>
        <Col span={24}>
          <OrderTabs
            document={document}
            action={ACTIONS.EDIT}
            dbTable={TABLES.EDITING_ORDER}
          ></OrderTabs>
        </Col>
      </Row>
      <BottomPageTitle title="Редагування замовлення"></BottomPageTitle>
      <BackTo to={"/orders"}></BackTo>
    </>
  );
};
export default EditOrder;
