import { Col, Row, Divider } from "antd";

export const TableTotals = (props) => {
  return (
    <Row style={{ padding: "0 5px" }}>
      <Col style={{ textAlign: "right" }}>Всього: {props.quantity}</Col>
      <Divider type="vertical" dashed={false} />
      <Col style={{ textAlign: "right" }}>Сума: {props.total}</Col>
      {props?.currency ? (
        <>
          <Divider type="vertical" dashed={false} />
          <Col style={{ textAlign: "right" }}>Валюта: {props.currency}</Col>
        </>
      ) : (
        ""
      )}
    </Row>
  );
};
