import React, { useState, useEffect } from "react";
import BottomPageTitle from "../components/header/BottomPageTitle";
import ProductsTabs from "../components/products/ProductsTabs";
import BackButton from "../components/global/BackButton";
import { listToTree } from "../utils/list-to-tree";
import InfoProductModal from "../components/products/InfoProductModal";
import { getAllRecords } from "../db/dexie-db/functions";

export default function Productspage() {
  // const { getAll: getAllCategories } = useIndexedDBStore("Categories");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalInfoProductOpen, setIsModalInfoProductOpen] = useState(false);
  const [productToModal, setProductToModal] = useState();

  useEffect(() => {
    const getData = async () => {
      const categories = await getAllRecords("Categories");
      const categoriesTree = listToTree(categories);

      setCategories(categoriesTree);
      setLoading(false);
    };

    getData();
  }, []);

  return (
    <>
      <ProductsTabs
        loading={loading}
        setLoading={setLoading}
        categories={categories}
        setIsModalInfoProductOpen={setIsModalInfoProductOpen}
        setProductToModal={setProductToModal}
      ></ProductsTabs>
      <InfoProductModal
        isModalInfoProductOpen={isModalInfoProductOpen}
        setIsModalInfoProductOpen={setIsModalInfoProductOpen}
        productToModal={productToModal}
      ></InfoProductModal>
      <BottomPageTitle title="Список товарів"></BottomPageTitle>
      <BackButton></BackButton>
    </>
  );
}
