import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
// import MainPage from "../pages/MainPage";
import Orderspage from "../pages/orders/Orders";
import NoPage from "../components/Nopage";
import CreateOrder from "../pages/orders/CreateOrder";
import EditOrder from "../pages/orders/EditOrder";
import ViewOrder from "../pages/orders/ViewOrder";
import Loginpage from "../pages/Loginpage";
import RequireAuth from "../hoc/RequireAuth";
import IcoPage from "../pages/ico/IcoPage";
import CreateIco from "../pages/ico/CreateIco";
import EditIco from "../pages/ico/EditIco";
import MainPage2 from "../pages/MainPage2";
import Clientspage from "../pages/Clientspage";
import Productspage from "../pages/Productspage";
import Storagespage from "../pages/Storagespage";
import Stockspage from "../pages/Stockspage";
import Debtspage from "../pages/Debtspage";
import MapsPage from "../pages/MapsPage";
import RequireAuthAdmin from "../hoc/RequireAuthAdmin";
import AdminLoginPage from "../pages/AdminLoginPage";

const AppRouter = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout></Layout>
            </RequireAuth>
          }
        >
          <Route index element={<MainPage2></MainPage2>}></Route>
          <Route path="orders" element={<Orderspage></Orderspage>}></Route>
          <Route
            path="orders/create"
            element={<CreateOrder></CreateOrder>}
          ></Route>
          <Route
            path="orders/edit/:id"
            element={<EditOrder></EditOrder>}
          ></Route>
          <Route
            path="orders/view/:id"
            element={<ViewOrder></ViewOrder>}
          ></Route>
          <Route path="ico" element={<IcoPage></IcoPage>}></Route>
          <Route path="ico/create" element={<CreateIco></CreateIco>}></Route>
          <Route path="ico/edit/:id" element={<EditIco></EditIco>}></Route>
          <Route path="clients" element={<Clientspage></Clientspage>}></Route>
          <Route
            path="storages"
            element={<Storagespage></Storagespage>}
          ></Route>
          <Route path="stocks" element={<Stockspage></Stockspage>}></Route>
          <Route path="debts" element={<Debtspage></Debtspage>}></Route>
          <Route
            path="products"
            element={<Productspage></Productspage>}
          ></Route>
          <Route path="*" element={<NoPage></NoPage>}></Route>
        </Route>
        <Route path="/login" element={<Loginpage></Loginpage>}></Route>
        <Route path="/admin">
          <Route
            index
            element={
              <RequireAuthAdmin>
                <MapsPage></MapsPage>
              </RequireAuthAdmin>
            }
          ></Route>
        </Route>
        <Route
          path="admin/login"
          element={<AdminLoginPage></AdminLoginPage>}
        ></Route>
      </Routes>
    </div>
  );
};

export default AppRouter;
