import { Navigate, useLocation } from "react-router-dom";

import React from "react";
import { useAuth } from "../hook/useAuth";

const RequireAuthAdmin = ({ children }) => {
  const location = useLocation();
  const { adminUser } = useAuth();

  if (!adminUser) {
    return <Navigate to="/admin/login" state={{ from: location }}></Navigate>;
    // return <Navigate to="" state={{ from: location }}></Navigate>;
  }
  return children;
};

export default RequireAuthAdmin;
