import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  InputNumber,
  Spin,
  Space,
  Divider,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ModalChooseClient from "../../ModalChooseClient";
import { getRecordByKeyValue } from "../../../../db/dexie-db/functions";
import {
  ACTIONS,
  LOCAL_STORAGE_FIELDS,
  TABLES,
} from "../../../../_CONSTANTS/constants";
import {
  getClientsData,
  getDocProperties,
  getFirmsData,
  getPriceTypesData,
  getStoragesData,
} from "../../../../db/document-functions/document-functions";

const InfoTab = (props) => {
  const [disabledPriceType, setDisabledPriceType] = useState(
    props?.disabledFields === true ? true : false
  );
  const [isModalChooseClientOpen, setIsModalChooseClientOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [clients, setClients] = useState([]);
  const [firms, setFirms] = useState([]);
  const [priceTypes, setPriceTypes] = useState([]);
  const [storages, setStorages] = useState([]);
  const [docProperties, setDocProperties] = useState([]);
  const [clientDebt, setClientDebt] = useState(0);

  useEffect(() => {
    if (props.client) updateClientDebt(props.client);
  }, [props?.client]);

  useEffect(() => {
    setFieldsData();
  }, []);

  const setFieldsData = async () => {
    setIsSpinning(true);
    setClients(await getClientsData());
    setPriceTypes(await getPriceTypesData());
    const storages = await getStoragesData();
    setStorages(storages);
    if (!props.storage && storages.length === 1)
      props.setStorage(storages[0].value);
    const firms = await getFirmsData();
    if (!props.firm && firms.length === 1) props.setFirm(firms[0].value);
    setFirms(firms);

    const docProperties = await getDocProperties();

    if (docProperties) {
      setDocProperties(docProperties);
      props.setDocProperty(docProperties[0]);
    }

    setIsSpinning(false);
  };

  const showModalChooseClient = async () => {
    setIsModalChooseClientOpen(true);
  };

  const onChangeStorage = (value) => {
    localStorage.setItem(LOCAL_STORAGE_FIELDS.CURRENT_STORAGE, value);
    props.setStorage(value);
  };

  const setDefaultPriceType = async (clientGuid) => {
    const client = await getRecordByKeyValue(TABLES.CLIENTS, {
      guid: clientGuid,
    });
    if (client.defaultPriceTypeGuid) {
      onChangePriceType(client.defaultPriceTypeGuid);
      setDisabledPriceType(true);
    } else {
      setDisabledPriceType(false);
    }
  };

  const onChangeComment = (event) => {
    const comment = event.target.value;
    localStorage.setItem(
      LOCAL_STORAGE_FIELDS.CURRENT_COMMENT,
      event.target.value
    );
    props.setComment(comment);
  };
  const onChangeClient = async (value) => {
    localStorage.setItem(LOCAL_STORAGE_FIELDS.CURRENT_CLIENT, value);
    setDefaultPriceType(value);
    props.setClient(value);
  };

  const updateClientDebt = async (clientGuid) => {
    const debt = await getRecordByKeyValue(TABLES.DEBTS, {
      clientGuid,
    });
    if (debt) {
      setClientDebt(debt.clientDebt);
    } else {
      setClientDebt(0);
    }
  };

  const onChangePriceType = (value) => {
    localStorage.setItem(LOCAL_STORAGE_FIELDS.CURRENT_PRICE_TYPE, value);
    props.setPriceType(value);
  };

  const onChangeFirm = (value) => {
    localStorage.setItem(LOCAL_STORAGE_FIELDS.CURRENT_FIRM, value);
    props.setFirm(value);
  };

  const onChangeDocProperty = (value) => {
    props.setDocProperty(value);
  };

  return (
    <>
      <Spin spinning={isSpinning}>
        <Row style={{ justifyContent: "center", margin: "10px 0" }}>
          <Col span={18}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Select
                disabled={props.disabledFields}
                value={props?.firm}
                size="large"
                style={{ width: "100%", textAlign: "left" }}
                aria-required={true}
                placeholder="Організація"
                optionFilterProp="children"
                onSelect={onChangeFirm}
                onChange={onChangeFirm}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={firms}
              />
              <Select
                disabled={props.disabledFields}
                value={props?.storage}
                size="large"
                style={{ width: "100%", textAlign: "left" }}
                aria-required={true}
                placeholder="Склад"
                optionFilterProp="children"
                onSelect={onChangeStorage}
                onChange={onChangeStorage}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={storages}
              />
              <Row>
                <Col span={20}>
                  <Select
                    disabled={props.action === ACTIONS.VIEW ? true : false}
                    value={props?.client}
                    size="large"
                    style={{ width: "100%", textAlign: "left" }}
                    showSearch
                    placeholder="Контрагент"
                    optionFilterProp="children"
                    onChange={onChangeClient}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={clients}
                  />
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  <Button
                    onClick={showModalChooseClient}
                    disabled={props.action === ACTIONS.VIEW ? true : false}
                    shape="default"
                    size="large"
                    icon={<SearchOutlined />}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={19}>
                  <Select
                    disabled={
                      props.action === ACTIONS.CREATE ? disabledPriceType : true
                    }
                    value={props?.priceType}
                    size="large"
                    style={{ width: "100%", textAlign: "left" }}
                    placeholder="Тип ціни"
                    optionFilterProp="children"
                    onChange={onChangePriceType}
                    options={priceTypes}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    style={{ width: "100%", textAlign: "center" }}
                    shape="default"
                    size="large"
                    disabled
                  >
                    {props?.currency}
                  </Button>
                </Col>
              </Row>
              <Input
                disabled={props.disabledFields}
                value={props?.comment}
                allowClear={true}
                size="large"
                style={{ width: "100%" }}
                placeholder="Примітка"
                onChange={onChangeComment}
              />
              <DatePicker
                value={dayjs(props?.document?.modified?.$d)}
                style={{ width: "100%" }}
                showTime
                size="large"
                disabled={true}
                format={"DD-MM-YYYY HH:mm:ss"}
                placeholder={"Дата"}
              />
              <InputNumber
                value={props?.sum}
                style={{ width: "100%" }}
                parser={(value) => value.replace(",", ".")}
                precision={2}
                size="large"
                addonAfter="грн"
                disabled={true}
                type={"tel"}
                required={true}
                placeholder={"0"}
              />

              {docProperties ? (
                <Select
                  disabled={props.action === ACTIONS.CREATE ? false : true}
                  value={props?.docProperty}
                  size="large"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Властивість"
                  optionFilterProp="children"
                  onChange={onChangeDocProperty}
                  options={docProperties}
                />
              ) : (
                ""
              )}
              <Divider style={{ margin: "5px" }}></Divider>
              <InputNumber
                value={clientDebt}
                style={{ width: "100%" }}
                parser={(value) => value.replace(",", ".")}
                precision={2}
                size="large"
                addonAfter="борг"
                disabled={true}
                type={"tel"}
                required={true}
                placeholder={"0"}
              />
            </Space>
          </Col>
        </Row>
        <ModalChooseClient
          isModalChooseClientOpen={isModalChooseClientOpen}
          setIsModalChooseClientOpen={setIsModalChooseClientOpen}
          onChangeClient={onChangeClient}
        ></ModalChooseClient>
      </Spin>
    </>
  );
};
export default InfoTab;
