import { Button, Col, InputNumber, Modal, Popconfirm, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

export default function ModalEditProductQty(props) {
  const [qtyInputValue, setQtyInputValue] = useState();

  useEffect(() => {
    setTimeout(() => {
      setQtyInputValue("");
      setInputLastProductQtyFocus();
    }, 1);
  }, [props.productToChangeQty]);

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };
  const [inputLastProductQty, setInputLastProductQtyFocus] = UseFocus();

  const decrementQty = () => {
    if (qtyInputValue === 0 || qtyInputValue === "" || qtyInputValue === 1) {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(+qtyInputValue - 1);
    }
  };

  const incrementQty = () => {
    if (qtyInputValue === 0 || qtyInputValue === "") {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(+qtyInputValue + 1);
    }
  };

  const saveButtonHandler = () => {
    props.handleChangeQty(props.productToChangeQty, qtyInputValue);
    props.setIsModalChangeLastProductOpen(false);
  };

  const plusButtonHandler = () => {
    const enteredQty = qtyInputValue === "" ? 1 : +qtyInputValue;
    const newQty = +props.productToChangeQty.qty + +enteredQty;
    props.handleChangeQty(props.productToChangeQty, newQty);
    props.setIsModalChangeLastProductOpen(false);
  };

  const deleteProductHandler = async (product) => {
    await props.removeProductFromDocument(product);
    props.setIsModalChangeLastProductOpen(false);
  };

  const footer = [
    <>
      <Row style={{ textAlign: "center" }}>
        <Col span={12}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={plusButtonHandler}
          >
            +
          </Button>
        </Col>

        <Col span={12}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={saveButtonHandler}
          >
            Зберегти
          </Button>
        </Col>
      </Row>
    </>,
  ];

  return (
    <>
      <Modal
        title={
          <div style={{ width: "95%" }}>{props.productToChangeQty?.title}</div>
        }
        style={{ top: "10px" }}
        open={props.isModalChangeLastProductOpen}
        onCancel={() => {
          props.setIsModalChangeLastProductOpen(false);
        }}
        footer={footer}
        afterClose={() => {
          setQtyInputValue("");
        }}
      >
        <Row>
          {`Облік: ${props.productToChangeQty?.stockQty}`}
          <br></br>
          {`Ціна: ${props.productToChangeQty?.price.toFixed(2)} грн`}
        </Row>
        <Row
          style={{
            margin: "15px",
          }}
        >
          <Col span={6} style={{ textAlign: "center" }}>
            <Popconfirm
              title={"Видалити позицію?"}
              onCancel={() => {
                return;
              }}
              onConfirm={() => deleteProductHandler(props.productToChangeQty)}
            >
              <Button icon={<DeleteOutlined />} size="large"></Button>
            </Popconfirm>
          </Col>
          <Col span={18} style={{ textAlign: "center" }}>
            <InputNumber
              className="changeQtyInput"
              size="large"
              ref={inputLastProductQty}
              autoFocus={true}
              {...(props.productToChangeQty?.isWeight
                ? null
                : { precision: 0 })}
              style={{ width: "70%" }}
              controls={false}
              min={0}
              type={"tel"}
              placeholder="К-ть"
              parser={(value) => {
                if (props.productToChangeQty?.isWeight) {
                  const valueFloat = parseFloat(value.replace(",", "."));
                  return valueFloat;
                }
                const valueInt = parseInt(value.replace(",", "."));
                return valueInt;
              }}
              onChange={(e) => {
                setQtyInputValue(e);
              }}
              value={qtyInputValue}
              addonBefore={
                <span style={{ padding: "10px" }} onClick={decrementQty}>
                  -
                </span>
              }
              addonAfter={
                <span style={{ padding: "10px" }} onClick={incrementQty}>
                  +
                </span>
              }
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
