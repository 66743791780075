import { createContext, useState } from "react";
import React from "react";
import Cookies from "universal-cookie";
import { addHours } from "../utils/NormalizeData";
import { message } from "antd";
import { checkToRemoveDocuments, logOut } from "../api/login/Login";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const cookie = new Cookies();
  const loggedInUser = cookie.get("_auth_user");
  const loggedInAdmin = sessionStorage.getItem("admin");

  const [user, setUser] = useState(
    loggedInUser == undefined ? null : loggedInUser
  );

  const [adminUser, setAdminUser] = useState(
    loggedInAdmin == undefined ? null : JSON.parse(loggedInAdmin)
  );

  const signIn = async (newUser, cb) => {
    const cookie = new Cookies();
    const midnight = new Date();
    midnight.setUTCHours(24, 0, 0, 0);

    const expiresDate = addHours(midnight, 3);
    cookie.set("_auth_user", newUser, {
      expires: expiresDate,
    });
    cookie.set("_auth_token", newUser.token, {
      expires: expiresDate,
    });

    await checkToRemoveDocuments(newUser.username);

    setUser(newUser);
    cb();
  };

  const updateCurrentUser = (user) => {
    // sessionStorage.setItem("user", JSON.stringify(user));
    const cookie = new Cookies();
    cookie.set("_auth_user", user);
    setUser(user);
  };

  const signOut = async (cb) => {
    const loggedOut = await logOut(user.username);
    if (!loggedOut) {
      message.info("Не вдалося звільнити місце для входу");
    }
    setUser(null);
    const cookie = new Cookies();
    cookie.remove("_auth_user");
    cookie.remove("_auth_token");
    cb();
  };

  const signInAdmin = (newAdmin, cb) => {
    sessionStorage.setItem("admin", JSON.stringify(newAdmin));
    setAdminUser(newAdmin);
  };

  const signOutAdmin = (cb) => {
    setUser(null);
    sessionStorage.removeItem("admin");
    // cb();
  };

  const value = {
    user,
    signIn,
    signOut,
    updateCurrentUser,
    adminUser,
    signInAdmin,
    signOutAdmin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
