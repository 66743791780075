import React from "react";
import DateRangePicker from "./MapFilterComponents/DateRangePicker";
import AgentsList from "./MapFilterComponents/AgentsList";
import { Button, Col, Drawer, FloatButton, Row } from "antd";

export default function MapFilter(props) {
  return (
    <>
      <Drawer
        open={props.isMapFilterOpen}
        onClose={() => props.setIsMapFilterOpen(false)}
        closable={false}
        contentWrapperStyle={{ width: "max-content", height: "max-content" }}
        placement="left"
        mask={false}
        maskClosable={false}
      >
        <Row>
          <Col>
            <Row
              style={{
                padding: "5px",
                display: "block",
                width: "100%",
                textAlign: "center",
              }}
            >
              <DateRangePicker
                setDates={props.setDates}
                dates={props.dates}
              ></DateRangePicker>
            </Row>
            <Row style={{ padding: "5px" }}>
              <AgentsList
                agents={props.agents}
                setAgents={props.setAgents}
                setSelectedAgent={props.setSelectedAgent}
                selectedAgent={props.selectedAgent}
              ></AgentsList>
            </Row>
            <Row
              style={{
                padding: "5px",
                display: "block",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Button type="primary" onClick={props.updateMap}>
                Отримати
              </Button>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
