import { getServer } from "../../utils/getEnvironment";
const server = getServer();

export const getAgentsByCompany = async (companyId) => {
  const responce = await fetch(server + "/server/admin/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-agents-by-company-id", companyId }),
  });

  return await responce.json();
};

export async function login(username, password) {
  const responce = await fetch(server + "/server/admin/server.php", {
    method: "POST",
    body: JSON.stringify({ username, password, method: "admin-login" }),
  });

  return await responce.json();
}

export const getAgentsDocumentsCoordinates = async (agents, dates) => {
  const dateFrom = dates[0] === "" ? "01.01.2000" : dates[0];
  const dateTo = dates[1] === "" ? "01.01.2100" : dates[1];

  const responce = await fetch(server + "/server/admin/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "get-agents-documents",
      agents,
      dateFrom,
      dateTo,
    }),
  });

  return await responce.json();
};

export const addDocumentRecordToSql = async (doc, userId) => {
  const responce = await fetch(server + "/server/admin/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "add-document-record-to-sql", doc, userId }),
  });

  return await responce.json();
};
