export const getDocumentSum = async (products) => {
  const { total } = products.reduce(
    (totalAndQnt, docProduct) => {
      return {
        total: totalAndQnt.total + Number(docProduct.qty * docProduct.price),
      };
    },
    {
      total: 0,
    }
  );
  return total;
};

export const getTableTotals = (products) => {
  const { total, quantity } = products.reduce(
    (totalAndQnt, docProduct) => {
      return {
        total: totalAndQnt.total + Number(docProduct.qty * docProduct.price),
        quantity: totalAndQnt.quantity + Number(docProduct.qty),
      };
    },
    {
      total: 0,
      quantity: 0,
    }
  );
  return { total: +total.toFixed(2), quantity: +quantity.toFixed(2) };
};

export const getOrdersTotals = (documents) => {
  const { total } = documents.reduce(
    (totalAndQnt, doc) => {
      return {
        total: totalAndQnt.total + doc.sum,
        //  quantity: totalAndQnt.quantity + Number(docProduct.qty),
      };
    },
    {
      total: 0,
    }
  );
  return { total: +total.toFixed(2), quantity: documents.length };
};
