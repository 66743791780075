import { message } from "antd";
import Dexie from "dexie";

const stores = {
  Products: "++id, title, barcode, art, guid, stockQty, parentCode, isWeight",
  Categories: "++id, code, title, parentCode",
  Price: "++id, priceTypeGuid, productGuid, priceValue",
  CurrentOrderProducts: "&guid",
  EditOrderProducts: "&guid",
  Orders:
    "++id, clientGuid, date, firmGuid, priceTypeGuid, exported, coordinates",
  Clients: "++id, name, address, guid, defaultPriceTypeGuid, parentCode",
  ClientsCategories: "++id, code, title, parentCode",
  Price: "++id, priceTypeGuid, productGuid, priceValue",
  PriceTypes: "++id, guid, name",
  Firms: "++id, guid, name",
  Ico: "++id, clientGuid, sum, comment, exported, date",
  Stocks: "++id, storageGuid, productGuid, productStock",
  Storages: "++id, guid, name",
  Debts: "++id, clientGuid",
  DocProperties: "++id, &guid",
};

export const db = new Dexie("WAAPP.SALES");
db.version(6).stores(stores);
try {
  db.version(7).stores({
    Products:
      "++id, title, *barcodes, art, guid, stockQty, parentCode, isWeight",
  });
  db.version(8).stores({
    Ico: "++id, clientGuid, sum, comment, exported, date, sourceDocGuid",
  });
  db.version(9).stores({
    Filters: "&id",
    Products:
      "++id, title, *barcodes, art, guid, stockQty, parentCode, isWeight, *filtersIds",
  });
} catch (error) {
  message.error("Помилка оновлення бази даних");
}

// export const openDataBase = () => {
//   const db = new Dexie("WAAPP.SALES");
//   db.version(6).stores();

//   try {
//     db.version(7).stores({
//       Products:
//         "++id, title, *barcodes, art, guid, stockQty, parentCode, isWeight",
//     });
//     // .upgrade(async (tx) => {
//     //   const Suppliers = await tx.table("Suppliers").toArray();
//     //   await tx.table("SuppliersTemp").bulkAdd(Suppliers);
//     // });
//   } catch (error) {
//     message.error("Помилка оновлення бази даних");
//   }

//   db.open().catch(async (err) => {
//     console.log(err);
//     alert("Помилка відкриття бази даних");
//     // await db.delete();
//     // window.location.reload();
//   });

//   return db;
// };
