import { Table } from "antd";
import React from "react";

export default function StocksTable(props) {
  const columns = [
    {
      title: "Товар",
      dataIndex: "productName",
      width: "50%",
      render: (_, record) => {
        return record.product.title;
      },
      sorter: (a, b) => a.productName - b.productName,
    },
    {
      title: "Склад",
      dataIndex: "storageName",
      width: "40%",
      render: (_, record) => {
        return record.storage.name;
      },
      sorter: (a, b) => a.stockName - b.stockName,
    },
    {
      title: "Залишок",
      dataIndex: "qty",
      width: "10%",
      render: (_, record) => {
        return record.productStock;
      },
      sorter: (a, b) => a.stockQty - b.stockQty,
    },
  ];

  return (
    <>
      <Table
        loading={props.loading}
        pagination={{ position: ["bottomCenter"] }}
        columns={columns}
        dataSource={props.stocks}
      />
    </>
  );
}
